import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaquinasAction from '../../../store/ducks/maquinas';
// import { Container } from './styles';

export default function MaquinaSlr() {
  const dispatch = useDispatch();
  const slrData = useSelector((store) => store.maquinas.slrResult);
  const slrSelecionada = useSelector((store) => store.maquinas.slrSelecionada);
  return (
    <div className="w-full md:w-1/3 p-2">
      <h4 className="font-normal text-secondary mb-2">SLR:</h4>
      <select
        value={slrSelecionada}
        onChange={({ target }) => dispatch(MaquinasAction.setSelectSlr(target.value))}
        className="
          h-[50px]
          w-full
          bg-textSecondary
          text-text
          p-2
          border-textSecondary
          outline-none
          focus:border-text
          bg-textSecondary
          rounded-md
        "
      >
        <option value="-">-</option>
        {slrData && slrData.map((slr) => (
          <option value={slr.SLR} key={slr.SLR}>
            {slr.SLR} - {slr.DESCRICAO}
          </option>
        ))}
      </select>
    </div>
  );
}
