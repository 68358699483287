/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// import { Container } from './styles';

export default function HeaderComponent() {
  const navigation = useHistory();
  const [pathSelect, setPathSelect] = useState('chamado');

  function handlePathSelect(path) {
    if (pathSelect === path) {
      return 'bg-textSecondary text-buttonPrimary';
    }

    return 'bg-buttonPrimary text-textSecondary';
  }

  return (
    <div
      className="h-[50px] w-full bg-secondary flex flex-row items-center p-4 cursor-pointer"
    >
      <div className="flex flex-col border-r-[1px] pr-2 mr-2">
        <h3 className="text-textSecondary font-bold text-sm">BasePro</h3>
        {/* <h3 className="text-textSecondary font-thin text-xs">Gerenciador de Aplicativo</h3> */}
      </div>
      <button
        onClick={() => {
          navigation.push('/app/chamados');
          setPathSelect('chamado');
        }}
        className={`flex flex-row items-center justify-center ${handlePathSelect('chamado')} cursor-pointer rounded`}
        type="button"
      >
        <h3 className="font-semithin text-sm px-4 py-2 text-center">Chamados</h3>
      </button>
      <button
        onClick={() => {
          navigation.push('/app/aplicativo');
          setPathSelect('aplicativo');
        }}
        className={`flex flex-row items-center justify-center ${handlePathSelect('aplicativo')} cursor-pointer rounded`}
        type="button"
      >
        <h3 className="font-semithin text-sm px-4 py-2">Aplicativo</h3>
      </button>
      <button
        onClick={() => {
          navigation.push('/app/configuracoes');
          setPathSelect('configuracoes');
        }}
        className={`flex flex-row items-center justify-center ${handlePathSelect('configuracoes')} cursor-pointer rounded md:ml-2`}
        type="button"
      >
        <h3 className="font-semithin text-sm px-4 py-2">Configurações</h3>
      </button>
      <button
        onClick={() => {
          navigation.push('/app/usuarios');
          setPathSelect('usuarios');
        }}
        className={`flex flex-row items-center justify-center ${handlePathSelect('usuarios')} cursor-pointer rounded md:ml-2`}
        type="button"
      >
        <h3 className="font-semithin text-sm px-4 py-2">Usuarios</h3>
      </button>
    </div>
  );
}
