import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MaquinasActions from '../../store/ducks/maquinas';
import NavigationAction from '../../store/ducks/navigation';
import UtilsActions from '../../store/ducks/utils';
import UserActions from '../../store/ducks/user';
import DataContatoPicker from './DataContatoPicker';
import LojaSelect from './LojaSelect';
import MaquinaSlr from './MaquinaSlr';
import MaquinaModelo from './MaquinaModelo';
import MaquinaSerie from './MaquinaSerie';
import OrigemSelect from './OrigemSelect';
import SelectClient from './SelectClient';
import DataClientSelect from './DataClientSelect';
import DadosChamado from './DadosChamado';

// import { Container } from './styles';

export default function NovoChamado() {
  const dispatch = useDispatch();
  const navigation = useHistory();
  const clientSelected = useSelector((store) => store.cliente.clientSelected);

  useEffect(() => {
    dispatch(MaquinasActions.getSlrRequest());
    dispatch(UtilsActions.getLojasRequest());
    dispatch(UtilsActions.getOrigemContatoRequest());
    dispatch(NavigationAction.setNavigation(navigation));
    dispatch(UserActions.userDataRequest());
  }, []);
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row items-center justify-start w-full px-4 mt-2">
        <h3 className="text-lg text-secondary font-semibold">
          Dados de cadastro
        </h3>
      </div>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full px-2">
        <MaquinaSlr />
        <MaquinaModelo />
        <MaquinaSerie />
      </div>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full px-2">
        <LojaSelect />
        <OrigemSelect />
        <DataContatoPicker />
        <SelectClient />
      </div>
      {clientSelected && (
        <DataClientSelect />
      )}
      <div className="flex flex-row items-center justify-start w-full px-4 mt-4">
        <h3 className="text-lg text-secondary font-semibold">
          Dados do chamado
        </h3>
      </div>
      <DadosChamado />
    </div>
  );
}
