/* eslint-disable no-underscore-dangle */
/* eslint-disable default-param-last */
/* eslint-disable no-unused-vars */
import { createReducer, createActions } from 'reduxsauce';
import NotificationAudio from '../../assets/sounds/notification.mp3';

const audioNotification = new Audio(NotificationAudio);

const { Types, Creators } = createActions({
  setChatSelect: ['data'],
  setChatsAtribuido: ['data'],
  sendMessageRequest: ['data', 'chamadoId', 'messageFile', 'orcamento'],
  sendMessageSuccess: null,
  updateChatNotification: ['data'],
  chatDisconnectRequest: ['data'],
  chatDisconnectSuccess: null,
  openChatRequest: ['data'],
  openChatSuccess: ['data'],
  loadingCancel: null,
});

export const ChatTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  chatSelect: null,
  chatsAtribuido: null,
  chatDisconnect: null,
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CHAT_SELECT]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    chatSelect: data,
  }),
  [Types.SET_CHATS_ATRIBUIDO]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    chatsAtribuido: data,
  }),
  [Types.SEND_MESSAGE_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
  }),
  [Types.SEND_MESSAGE_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
  }),
  [Types.CHAT_DISCONNECT_REQUEST]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    chatDisconnect: data,
  }),
  [Types.CHAT_DISCONNECT_SUCCESS]: (state = INITIAL_STATE) => {
    const { chatDisconnect, chatsAtribuido } = state;
    const formatChatsAtribuido = chatsAtribuido.filter(
      (chat) => chat.chamadoId !== chatDisconnect.chamadoId,
    );

    return {
      ...state,
      chatDisconnect: null,
      chatSelect: null,
      chatsAtribuido: formatChatsAtribuido.length > 0 ? formatChatsAtribuido : null,
    };
  },
  [Types.OPEN_CHAT_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
  }),
  [Types.OPEN_CHAT_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
  }),
  [Types.UPDATE_CHAT_NOTIFICATION]: (state = INITIAL_STATE, { data }) => {
    const { chatSelect, chatsAtribuido } = state;

    if (chatSelect && chatSelect.chamadoId === data._id) {
      const newChatSelect = {
        chamadoId: data._id,
        cliente_nome: data.clientData.nome,
        notification: 0,
        timeline: data.timeline,
      };
      const newChatsAtribuido = chatsAtribuido.map((chat) => (
        chat.chamadoId === data._id
          ? newChatSelect
          : chat
      ));
      audioNotification.play();

      return {
        ...state,
        chatSelect: newChatSelect,
        chatsAtribuido: newChatsAtribuido,
      };
    }

    const newChatSelect = {
      chamadoId: data._id,
      cliente_nome: data.clientData.nome,
      timeline: data.timeline,
    };

    const newChatsAtribuido = chatsAtribuido.map((chat) => (
      chat.chamadoId === data._id
        ? { ...newChatSelect, notification: chat.notification + 1 }
        : chat
    ));

    audioNotification.play();

    return {
      ...state,
      chatsAtribuido: newChatsAtribuido,
    };
  },
});
