/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
// import Cookies from 'universal-cookie';
import { put, call, select } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import ChatActions from '../ducks/chat';
import ClienteActions from '../ducks/cliente';
import api from '../../services/api';
// import { getChamados } from './cliente';

export function* sendMsg({
  data, chamadoId, messageFile = false, orcamento = false,
}) {
  try {
    const chatsOpenStore = yield select((store) => store.chat.chatsOpen);

    let responseData = {};

    if (messageFile) {
      const { fileData, ...restData } = data;

      const fileForm = new FormData();
      fileForm.append('fileData', fileData);

      const { data: response } = yield call(api.post, `/chamado/file/${chamadoId}`, fileForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const formatMessageData = {
        ...restData,
        fileData: response._id,
      };

      const { data: responseMessage } = yield call(
        api.post,
        `/chamado/message-file/${chamadoId}`,
        formatMessageData,
      );

      responseData = responseMessage;
    } else {
      const { data: response } = yield call(api.post, `/chamado/message/${chamadoId}`, data);
      responseData = response;
    }

    const newChatSelect = {
      chamadoId: responseData._id,
      notification: 0,
      cliente_nome: responseData.clientData.nome,
      timeline: responseData.timeline,
    };

    yield put(ChatActions.setChatSelect(newChatSelect));

    if (chatsOpenStore) {
      const verifyChat = chatsOpenStore.map((chat) => (
        chat.chamadoId === newChatSelect.chamadoId ? newChatSelect : chat
      ));
      yield put(ChatActions.setOpenChats(verifyChat));
    }

    if (orcamento) {
      NotificationManager.success(
        'Orçamento enviado com sucesso',
        'Envio de orçamento',
      );
    }

    yield put(ClienteActions.getChamadosRequest());
    return yield put(ChatActions.sendMessageSuccess());
  } catch (error) {
    console.log('error msg', error);
  }
}

export function* openChat({ data }) {
  try {
    const chatsOpenStore = yield select((store) => store.chat.chatsOpen);

    if (chatsOpenStore) {
      const verifyChat = chatsOpenStore.filter((chat) => chat.chamadoId === data._id)[0];
      if (verifyChat) {
        return yield put(ChatActions.setChatSelect(verifyChat));
      }
    }

    const { data: response } = yield call(api.post, `/chamado/message-timeline/${data._id}`, data);

    const chatData = {
      chamadoId: data._id,
      notification: 0,
      cliente_nome: data.clientData.nome,
      timeline: response,
    };

    const opensChatsFormat = chatsOpenStore ? [...chatsOpenStore, chatData] : [chatData];

    yield put(ChatActions.setChatSelect(chatData));
    return yield put(ChatActions.setOpenChats(opensChatsFormat));
  } catch (error) {
    console.log('error openchat', error);
  }
}
