/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiSearchAlt } from 'react-icons/bi';
import { HiDocumentSearch } from 'react-icons/hi';
import { MdTimeline } from 'react-icons/md';
import { CgExtensionAdd } from 'react-icons/cg';
import { IoMdChatboxes, IoMdClose, IoMdLock } from 'react-icons/io';
import { BsCalendarPlus } from 'react-icons/bs';
import { format, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import ClientesAction from '../../store/ducks/cliente';
import ChatAction from '../../store/ducks/chat';
import DataTable from '../../components/DataTable';
import ModalComponent from '../../components/ModalComponent';
import TextInput from '../../components/TextInput';
import ButtonComponent from '../../components/Button';
import ModalCustom from '../../components/ModalCustom';
import findClientField from '../../utils/findClientField';
import serverUrl from '../../utils/serverUrl';
import colorsBadgeClients from '../../utils/colorsBadgeClients';
import ResumeTimeline from './ResumeTimeline';

export default function Home() {
  const navigation = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((store) => store.user.userData);
  const chamadosLoading = useSelector((store) => store.cliente.loading);
  const chamadosStore = useSelector((store) => store.cliente.chamados);
  const chatsOpenStore = useSelector((store) => store.chat.chatsOpen);

  const [chamadosData, setChamadosData] = useState(null);
  const [chamadoSelected, setChamadoSelected] = useState(null);
  const [resumeTimelineStatus, setResumeTimelineStatus] = useState(false);
  const [chamadoModalStatus, setChamadoModalStatus] = useState(false);
  const [chamadoFormModalStatus, setChamadoFormModalStatus] = useState(false);
  const [chamadoFormData, setChamadoFormData] = useState({
    codOs: '',
    orcPecas: '',
    orcServ: '',
    descContato: '',
  });

  useEffect(() => {
    dispatch(ClientesAction.getChamadosRequest());
    dispatch(ClientesAction.getChamadosAtribuidoRequest());
  }, []);

  useEffect(() => {
    if (chamadosStore) {
      setChamadosData(chamadosStore);
    }
  }, [chamadosStore]);

  function selectChamado(chamado) {
    setChamadoSelected(chamado);

    return setChamadoModalStatus(true);
  }

  function handleChamadoFormData(text, name) {
    return setChamadoFormData({
      ...chamadoFormData,
      [name]: text,
    });
  }

  function handleChamadoUpdate() {
    setChamadoModalStatus(false);
    setChamadoFormModalStatus(false);
    setChamadoFormData({
      codOs: '',
      orcPecas: '',
      orcServ: '',
      descContato: '',
    });
    return dispatch(ClientesAction.updateChamadosRequest(chamadoFormData, chamadoSelected._id));
  }

  const columns = [
    {
      title: 'Criado em',
      customSort: (a, b) => (a.createdAt > b.createdAt ? 1 : -1),
      render: (rowData) => (
        <span className="text-text">{`${format(
          parseISO(rowData.registeredAt),
          'dd-MM-yyyy',
        )}`}
        </span>
      ),
    },
    {
      title: 'Atribuido',
      sorting: false,
      cellStyle: {
        color: '#171819',
      },
      render: (rowData) => (
        <span>
          {rowData.atribuidoAt ? rowData.atribuidoAt.nome : 'N/A'}
        </span>
      ),
    },
    // {
    //   title: 'Localização',
    //   sorting: false,
    //   field: 'localizacao',
    //   cellStyle: {
    //     color: '#171819',
    //   },
    // },
    {
      title: 'Criado por',
      sorting: false,
      cellStyle: {
        color: '#171819',
      },
      render: (rowData) => (
        <span>{findClientField(rowData).nome}</span>
      ),
    },
    {
      title: 'Maq SLR',
      sorting: false,
      field: 'maqData.SLR',
      cellStyle: {
        color: '#171819',
      },
    },
    {
      title: 'Maq Modelo',
      sorting: false,
      field: 'maqData.Modelo',
      cellStyle: {
        color: '#171819',
      },
    },
    {
      title: 'Maq Serie',
      sorting: false,
      field: 'maqData.Serie',
      cellStyle: {
        color: '#171819',
      },
    },
    {
      title: 'Maq Parada',
      render: (rowData) => (
        <h3
          style={{
            color: rowData.maquinaParada ? '#ff6b6b' : '#239b55',
          }}
          className="font-semibold"
        >
          {rowData.maquinaParada ? 'Sim' : 'Não'}
        </h3>
      ),
    },
    {
      title: 'Status',
      sorting: false,
      cellStyle: {
        color: '#171819',
      },
      render: (rowData) => (
        <h3 className="font-semibold capitalize">
          {rowData.statusChamado.status}
        </h3>
      ),
    },
    {
      title: 'Status Desc',
      sorting: false,
      cellStyle: {
        color: '#171819',
      },
      render: (rowData) => (
        <h3 className="truncate">
          {rowData.statusChamado.descricao}
        </h3>
      ),
    },
    {
      title: 'Ação',
      sorting: false,
      render: (rowData) => (
        <div className="flex flex-row">
          <button
            type="button"
            onClick={() => {
              selectChamado(rowData);
              dispatch(ClientesAction.setChamadoSelect(rowData));
            }}
          >
            <BiSearchAlt color="#171819" size={20} />
          </button>
        </div>
      ),
    },
  ];

  function handleOpenChat() {
    return dispatch(ChatAction.openChatRequest(chamadoSelected));
  }

  function atribuirVeiculo() {
    dispatch(ClientesAction.atribuirChamadoRequest(chamadoSelected._id));

    return navigation.push('/app/chamados-atribuido');
  }

  function desatribuirVeiculo() {
    dispatch(ClientesAction.desatribuirChamadoRequest(chamadoSelected._id));

    return setChamadoModalStatus(!chamadoModalStatus);
  }

  return (
    <div className="flex w-full h-full flex-col items-center justify-start">
      <div className="flex flex-row items-center justify-between w-full mt-4 p-2">
        <button
          onClick={() => navigation.push('/app/novo-chamado')}
          type="button"
          className="flex flex-row items-center bg-secondary rounded py-2 px-4"
        >
          <h3 className="font-semibold text-textSecondary pr-2">Criar Chamado</h3>
          <BsCalendarPlus color="#fff" size={20} />
        </button>
        <button
          onClick={() => navigation.push('/app/chamados-atribuido')}
          type="button"
          className="flex flex-row items-center bg-sky-600 rounded py-2 px-4"
        >
          <h3 className="font-semibold text-textSecondary pr-2">Meus chamados</h3>
          <HiDocumentSearch color="#fff" size={20} />
        </button>
      </div>
      <DataTable columns={columns} data={chamadosData || []} title="Chamados" width="95vw" />
      {chamadoSelected && (
        <ModalCustom
          openState={chamadoModalStatus}
          closeAction={() => setChamadoModalStatus(!chamadoModalStatus)}
        >
          <div className="w-full h-full flex flex-col p-1 md:p-4 uppercase">
            <div className="flex flex-row items-center justify-between w-11/12 md:w-full pb-2 border-b">
              <h3 className="font-bold text-lg text-secondary">DETALHES DO CHAMADO</h3>

              <button type="button" onClick={() => setChamadoModalStatus(false)}>
                <h3 className="font-semibold text-secondary">Fechar</h3>
              </button>
            </div>
            <h3 className="mt-2 font-semibold text-lg text-secondary">AÇÕES</h3>
            <div className="flex flex-row flex-wrap items-end w-full">
              <button type="button" className="flex flex-row items-center bg-secondary rounded py-2 px-4" onClick={() => setChamadoFormModalStatus(!chamadoFormModalStatus)}>
                <h3 className="font-semibold text-textSecondary">Adicionar informações</h3>
                <CgExtensionAdd color="#fff" size={20} />
              </button>
              <button type="button" className="flex flex-row items-center bg-secondary rounded py-2 px-4 ml-2" onClick={() => setResumeTimelineStatus(!resumeTimelineStatus)}>
                <h3 className="font-semibold text-textSecondary">Ver timeline</h3>
                <MdTimeline color="#fff" size={20} />
              </button>
              {chamadoSelected.atribuidoAt && chamadoSelected.atribuidoAt._id === userData._id && (
                <button type="button" className="flex flex-row items-center bg-red-400 rounded py-2 px-4 ml-2" onClick={() => desatribuirVeiculo()}>
                  <h3 className="font-semibold text-textSecondary">Desatribuir chamado</h3>
                  <IoMdClose color="#fff" size={20} />
                </button>
              )}
              {chamadoSelected.atribuidoAt && chamadoSelected.atribuidoAt._id !== userData._id && (
                <button type="button" className="flex flex-row items-center bg-green-400 rounded py-2 px-4 ml-2" onClick={() => {}}>
                  <h3 className="font-semibold text-textSecondary">Chamado atribuido: {chamadoSelected.atribuidoAt.nome}</h3>
                  <IoMdLock color="#fff" size={20} />
                </button>
              )}
              {!chamadoSelected.atribuidoAt && (
                <button type="button" className="flex flex-row items-center bg-secondary rounded py-2 px-4 ml-2" onClick={() => atribuirVeiculo()}>
                  <h3 className="font-semibold text-textSecondary">Atribuir chamado</h3>
                  <IoMdChatboxes color="#fff" size={20} />
                </button>
              )}
            </div>
            {/* Detalhes cliente */}
            <div className="flex flex-col w-full mt-2">
              <h3 className="mb-4 font-semibold text-lg text-secondary border-b">Detalhes cliente</h3>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Criado por</h3>
                <h3>{findClientField(chamadoSelected).nome}</h3>
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Email</h3>
                <h3>{findClientField(chamadoSelected).email}</h3>
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Maq SLR</h3>
                <h3>{chamadoSelected.maqData.SLR}</h3>
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Maq Modelo</h3>
                <h3>{chamadoSelected.maqData.Modelo}</h3>
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Maq Serie</h3>
                <h3>{chamadoSelected.maqData.Serie}</h3>
              </div>
            </div>
            {/* Detalhes chamado */}
            <div className="flex flex-col w-full mt-2">
              <h3 className="mb-4 font-semibold text-lg text-secondary border-b">Detalhes chamado</h3>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Codigo error</h3>
                <h3>{chamadoSelected.codError}</h3>
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Maquina parada</h3>
                <h3>{chamadoSelected.maquinaParada ? 'Sim' : 'Não'}</h3>
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Localização</h3>
                <h3>{chamadoSelected.localizacao}</h3>
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Criado em</h3>
                <h3>{format(
                  parseISO(chamadoSelected.registeredAt),
                  'dd-MM-yyyy',
                )}
                </h3>
              </div>
              <div className="flex flex-col md:flex-row items-start justify-between w-full">
                <h3 className="font-semibold text-secondary">Descrição cliente</h3>
                <h3 className="text-start md:text-end w-10/12">{chamadoSelected.descricao}</h3>
              </div>
              {chamadoSelected.files_id && chamadoSelected.files_id.length > 0 && (
              <div className="flex flex-col md:flex-row items-start justify-between w-full">
                <h3 className="font-semibold text-secondary">Anexos do chamado</h3>
                <div className="flex flex-col items-start md:items-end justify-between w-full md:w-10/12">
                  {chamadoSelected.files_id.map((file) => (
                    // <a type="button" target="_blank" href={`https://api.tecnoeste.net/p21124/files/${file.fileName}`} rel="noreferrer">
                    <a type="button" target="_blank" href={`${serverUrl}/files/${file.fileName}`} rel="noreferrer">
                      <h3 className="text-start md:text-end w-10/12 font-thin text-sky-300 decoration-sky-500 underline">{file.fileName}</h3>
                    </a>
                  ))}
                </div>
              </div>
              )}
            </div>
            {/* Detalhes adicionais */}
            <div className="flex flex-col w-full mt-2">
              <h3 className="mb-4 font-semibold text-lg text-secondary border-b">Detalhes adicionais</h3>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Nº OS</h3>
                <h3>{chamadoSelected.codOs ? chamadoSelected.codOs : ''}</h3>
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Orcamento Peças</h3>
                <h3>{chamadoSelected.orcPecas ? chamadoSelected.orcPecas : ''}</h3>
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <h3 className="font-semibold text-secondary">Orcamento Serviços</h3>
                <h3>{chamadoSelected.orcServ ? chamadoSelected.orcServ : ''}</h3>
              </div>
              <div className="flex flex-col md:flex-row items-start justify-between w-full">
                <h3 className="font-semibold text-secondary">Descrição contato</h3>
                <h3 className="text-start md:text-end w-10/12">
                  {chamadoSelected.descContato ? chamadoSelected.descContato : ''}
                </h3>
              </div>
              <div className="h-[100px] md:h-[20px]" />
            </div>
          </div>
        </ModalCustom>
      )}
      <ModalComponent
        openState={chamadoFormModalStatus}
        closeAction={() => setChamadoFormModalStatus(!chamadoFormModalStatus)}
      >
        <div className="w-full h-full flex flex-col md:p-4 uppercase">
          <div className="flex flex-row items-center justify-between w-full border-b pb-2">
            <h3 className="font-bold text-lg text-secondary w-full">Dados adicionais</h3>
            <button type="button" onClick={() => setChamadoFormModalStatus(false)}>
              <h3 className="font-semibold text-secondary">Fechar</h3>
            </button>
          </div>
          <div className="mt-2 w-full md:w-6/12 mb-4">
            <h4 className="font-normal text-secondary mb-4">Codigo OS:</h4>
            <TextInput
              placeholder=""
              value={chamadoFormData.codOs}
              onChange={({ target }) => handleChamadoFormData(target.value, 'codOs')}
            />
            <h4 className="font-normal text-secondary mb-4">Orcamento Serviço:</h4>
            <TextInput
              placeholder=""
              value={chamadoFormData.orcServ}
              onChange={({ target }) => handleChamadoFormData(target.value, 'orcServ')}
            />
            <h4 className="font-normal text-secondary mb-4">Orcamento Peças:</h4>
            <TextInput
              placeholder=""
              value={chamadoFormData.orcPecas}
              onChange={({ target }) => handleChamadoFormData(target.value, 'orcPecas')}
            />
            <h4 className="font-normal text-secondary mb-4">Descrição contato:</h4>
            <TextInput
              textarea
              placeholder=""
              value={chamadoFormData.descContato}
              onChange={({ target }) => handleChamadoFormData(target.value, 'descContato')}
            />
            <ButtonComponent text="Atualizar" loading={chamadosLoading} onClick={() => handleChamadoUpdate()} />
            <div className="md:h-[20px]" />
          </div>
        </div>
      </ModalComponent>
      <ResumeTimeline chamado={chamadoSelected} open={resumeTimelineStatus} setOpen={setResumeTimelineStatus} />
    </div>
  );
}
