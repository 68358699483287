/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { BiShow, BiHide } from 'react-icons/bi';
import UtilsActions from '../../store/ducks/utils';
import UserActions from '../../store/ducks/user';
import TextInput from '../../components/TextInput';
import ButtonComponent from '../../components/Button';

// import { Container } from './styles';

export default function Usuarios() {
  const dispatch = useDispatch();
  const lojasData = useSelector((store) => store.utils.lojas);
  const newUserSuccess = useSelector((store) => store.user.newUserSuccess);
  const [inputPasswordType, setInputPasswordType] = useState('password');
  const [usuarioData, setUsuarioData] = useState({
    nome: '',
    usuario: '',
    email: '',
    senha: '',
    codLoja: '-',
    codFenix: '',
  });

  useEffect(() => {
    if (newUserSuccess) {
      dispatch(UserActions.setNewUserSuccess(false));
      setUsuarioData({
        nome: '',
        usuario: '',
        email: '',
        senha: '',
        codLoja: '-',
        codFenix: '',
      });
    }
  }, [newUserSuccess]);

  useEffect(() => {
    dispatch(UtilsActions.getLojasRequest());
  }, []);

  function handleUsuarioData(value, name) {
    return setUsuarioData({
      ...usuarioData,
      [name]: value,
    });
  }

  function createUser() {
    if (
      !usuarioData.nome
        || !usuarioData.usuario
        || !usuarioData.email
        || usuarioData.codLoja === '-'
        || !usuarioData.codFenix
    ) {
      return NotificationManager.warning(
        'Todos os campos são obrigatorios, verificar e tente novamente',
        'Novo usuario',
      );
    }

    return dispatch(UserActions.createNewUsersRequest(usuarioData));
  }

  return (
    <div className="h-full w-full">
      <div className="w-full p-2">
        <h3 className="text-xl font-semibold text-secondary mb-2">
          Criar um novo usuario
        </h3>
      </div>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full px-2">
        <div className="w-full md:w-1/3 p-2 h-[120px]">
          <h4 className="font-normal text-secondary mb-2">Nome:</h4>
          <TextInput
            onFocus={(event) => event.stopPropagation()}
            onClick={(event) => event.stopPropagation()}
            onBlur={({ target }) => {}}
            value={usuarioData.nome}
            onChange={({ target }) => handleUsuarioData(target.value, 'nome')}
            onKeyPress={(e) => {}}
            placeholder=""
          />
        </div>
        <div className="w-full items-start md:w-1/3 p-2 h-[120px]">
          <h4 className="font-normal text-secondary mb-2">*Usuario:</h4>
          <TextInput
            onFocus={(event) => event.stopPropagation()}
            onClick={(event) => event.stopPropagation()}
            onBlur={({ target }) => {}}
            value={usuarioData.usuario}
            onChange={({ target }) => handleUsuarioData(target.value, 'usuario')}
            onKeyPress={(e) => {}}
            placeholder=""
          />
          <h4 className="text-[0.65rem] font-normal text-secondary">(*)Mesmo usuario do fenix</h4>
        </div>
        <div className="w-full md:w-1/3 p-2 h-[120px]">
          <h4 className="font-normal text-secondary mb-2">E-mail:</h4>
          <TextInput
            onFocus={(event) => event.stopPropagation()}
            onClick={(event) => event.stopPropagation()}
            onBlur={({ target }) => {}}
            value={usuarioData.email}
            onChange={({ target }) => handleUsuarioData(target.value, 'email')}
            onKeyPress={(e) => {}}
            placeholder=""
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-start w-full px-2">
        <div className="w-full md:w-1/3 p-2 h-[120px]">
          <h4 className="font-normal text-secondary mb-2">Loja do usuario:</h4>
          <select
            value={usuarioData.codLoja}
            onChange={({ target }) => handleUsuarioData(target.value, 'codLoja')}
            className="
              h-[50px]
              w-full
              bg-textSecondary
              text-text
              p-2
              border-textSecondary
              outline-none
              focus:border-text
              bg-textSecondary
              rounded-md
            "
          >
            <option value="-">-</option>
            {lojasData && lojasData.map((loja) => (
              <option value={loja.GEF} key={loja.GEF}>
                {loja.FILIAL} ({loja.GEF})
              </option>
            ))}
          </select>
        </div>
        <div className="w-full items-start md:w-1/3 p-2 h-[120px]">
          <h4 className="font-normal text-secondary mb-2">Cod Fenix:</h4>
          <TextInput
            onFocus={(event) => event.stopPropagation()}
            onClick={(event) => event.stopPropagation()}
            onBlur={({ target }) => {}}
            value={usuarioData.codFenix}
            onChange={({ target }) => handleUsuarioData(target.value, 'codFenix')}
            onKeyPress={(e) => {}}
            placeholder=""
          />
        </div>
        <div className="w-full items-start md:w-1/3 p-2 h-[120px]">
          <h4 className="font-normal text-secondary mb-2">*Senha:</h4>
          <TextInput
            type={inputPasswordType}
            onFocus={(event) => event.stopPropagation()}
            onClick={(event) => event.stopPropagation()}
            onBlur={({ target }) => {}}
            value={usuarioData.senha}
            onChange={({ target }) => handleUsuarioData(target.value, 'senha')}
            onKeyPress={(e) => {}}
            placeholder=""
            iconRight={() => (
              <button
                type="button"
                onClick={() => {
                  setInputPasswordType(inputPasswordType === 'password' ? 'text' : 'password');
                }}
              >
                {inputPasswordType === 'password' ? (
                  <BiShow
                    color="#003d70"
                    size={20}
                  />
                ) : (
                  <BiHide
                    color="#003d70"
                    size={20}
                  />
                )}
              </button>
            )}
          />
          <h4 className="text-[0.65rem] font-normal text-secondary">(*)Mesma senha do fenix</h4>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-center w-full px-2">
        <div className="w-full md:w-1/3 p-2 h-[120px]">
          <ButtonComponent text="Criar" onClick={() => createUser()} />
        </div>
      </div>
    </div>
  );
}
