/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from '../../components/Button';
import DataTable from '../../components/DataTable';
import TextInput from '../../components/TextInput';
import ClienteActions from '../../store/ducks/cliente';
// import { Container } from './styles';

export default function Configuracoes() {
  const dispatch = useDispatch();
  const chamadoStatusStore = useSelector((store) => store.cliente.chamadoStatus);
  const chamadoVincStatus = useSelector((store) => store.cliente.chamadoVincStatus);

  const [statusGrupoSelect, setStatusGrupoSelect] = useState('');
  const [newStatusData, setNewStatusData] = useState({
    codStatus: '',
    subStatus: '',
    descricao: '',
  });

  const columns = [
    {
      title: 'Cod. Status',
      sorting: false,
      field: 'codStatus',
      cellStyle: {
        color: '#171819',
      },
    },
    {
      title: 'Status',
      sorting: false,
      field: 'status',
      cellStyle: {
        color: '#171819',
      },
    },
    {
      title: 'Sub status',
      sorting: false,
      field: 'subStatus',
      cellStyle: {
        color: '#171819',
      },
    },
    {
      title: 'Descrição',
      sorting: false,
      field: 'descricao',
      cellStyle: {
        color: '#171819',
      },
    },
  ];

  useEffect(() => {
    dispatch(ClienteActions.getChamadoStatusRequest());
  }, []);

  function handleCreateNewStatus() {
    const findStatusGrupo = chamadoStatusStore.filter(
      (status) => status.codStatus === statusGrupoSelect,
    )[0];

    const formatNewStatusData = {
      ...newStatusData,
      status: findStatusGrupo.status,
      codStatus: `${statusGrupoSelect}.${newStatusData.codStatus}`,
      // subStatus: `${findStatusGrupo.status} - ${newStatusData.subStatus}`,
      vinculado: findStatusGrupo._id,
    };

    dispatch(ClienteActions.createChamadoStatusRequest(formatNewStatusData));
  }

  return (
    <div className="h-full w-full">
      <div className="w-full p-2">
        <h3 className="text-xl font-semibold text-secondary mb-10">
          Criar um novo status chamado
        </h3>
        {chamadoStatusStore && (
          <div>
            <div className="flex flex-row w-full">
              <div className="w-2/6 mr-4">
                <h3 className="text-lg font-semithin text-secondary">
                  Grupo status
                </h3>
                <select
                  value={statusGrupoSelect}
                  onChange={({ target }) => {
                    setStatusGrupoSelect(target.value);

                    const findStatusGrupo = chamadoStatusStore.filter(
                      (status) => status.codStatus === target.value,
                    )[0];

                    dispatch(ClienteActions.getChamadoVincStatusRequest(findStatusGrupo._id));
                  }}
                  className="
                    h-[50px]
                    w-full
                    bg-textSecondary
                    text-text
                    p-2
                    border-textSecondary
                    outline-none
                    focus:border-text
                    bg-textSecondary
                    rounded-md
                  "
                >
                  <option value="-">-</option>
                  {chamadoStatusStore && chamadoStatusStore.map((statusGrupo) => (
                    <option value={statusGrupo.codStatus} key={statusGrupo.codStatus}>
                      {statusGrupo.codStatus} - {statusGrupo.status}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {statusGrupoSelect && (
              <div className="flex flex-row w-full mt-2">
                <div className="w-1/6">
                  <h3 className="text-lg font-semithin text-secondary">
                    Sub status
                  </h3>
                  <TextInput
                    className="ml-[-7px]"
                    value={newStatusData.codStatus}
                    onChange={({ target }) => setNewStatusData({
                      ...newStatusData,
                      codStatus: target.value,
                    })}
                    icon={() => (
                      <span className="text-secondary font-bold mb-[-1px]">
                        {statusGrupoSelect}.
                      </span>
                    )}
                  />
                </div>
                <div className="w-2/6 ml-4">
                  <h3 className="text-lg font-semithin text-secondary">
                    Sub status
                  </h3>
                  <TextInput
                    value={newStatusData.subStatus}
                    onChange={({ target }) => setNewStatusData({
                      ...newStatusData,
                      subStatus: target.value,
                    })}
                    placeholder=""
                  />
                </div>
                <div className="w-2/6 ml-4">
                  <h3 className="text-lg font-semithin text-secondary">
                    Descrição
                  </h3>
                  <TextInput
                    value={newStatusData.descricao}
                    onChange={({ target }) => setNewStatusData({
                      ...newStatusData,
                      descricao: target.value,
                    })}
                    placeholder=""
                  />
                </div>
              </div>
            )}
            {statusGrupoSelect && (
              <div className="flex flex-row w-full items-center justify-center mt-4">
                <div className="flex flex-row w-2/6 mt-4">
                  <ButtonComponent text="Criar" onClick={() => handleCreateNewStatus()} />
                </div>
              </div>
            )}
            {statusGrupoSelect && chamadoVincStatus && (
              <DataTable columns={columns} data={chamadoVincStatus || []} title="Status" width="95vw" />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
