/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { BsFillImageFill, BsFillTrash2Fill } from 'react-icons/bs';
import {
  MdVisibility, MdVisibilityOff, MdOutlineAddLink, MdArrowForwardIos,
} from 'react-icons/md';
import { RiImageAddFill } from 'react-icons/ri';
import { NotificationManager } from 'react-notifications';
import { format, parseISO } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useDispatch, useSelector } from 'react-redux';
import ClienteActions from '../../store/ducks/cliente';

import DataTable from '../../components/DataTable';
import TextInput from '../../components/TextInput';
import ButtonComponent from '../../components/Button';

import ModalComponent from '../../components/ModalComponent';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function Aplicativo() {
  const dispatch = useDispatch();
  const workFileRef = useRef(null);
  const clienteData = useSelector((store) => store.cliente);
  const novidadesData = useSelector((store) => store.cliente.novidades);
  const [newDestaqueModal, setNewDestaqueModal] = useState(false);
  const [destaqueFile, setDestaqueFile] = useState();
  const [urlDestaque, setUrlDestaque] = useState('');
  const [destaques, setDestaques] = useState(null);
  const [pushData, setPushData] = useState({
    title: '',
    body: '',
    data: {},
  });

  useEffect(() => {
    dispatch(ClienteActions.getDestaquesRequest());
  }, []);

  useEffect(() => {
    if (novidadesData) {
      setDestaques(novidadesData);
    }
  }, [novidadesData]);

  const columns = [
    {
      title: 'Imagem',
      sorting: false,
      headerStyle: {
        maxWidth: 60,
      },
      cellStyle: {
        width: 60,
      },
      render: (rowData) => (
        <a type="button" target="_blank" href={`https://api.tecnoeste.net/p21124/files/${rowData?.file_id?.fileName}`} rel="noreferrer">
          <BsFillImageFill color="#171819" size={20} />
        </a>
      ),
    },
    {
      title: 'Criado em',
      customSort: (a, b) => (a.createdAt > b.createdAt ? 1 : -1),
      align: 'center',
      headerStyle: {
        maxWidth: 100,
      },
      cellStyle: {
        maxWidth: 180,
      },
      render: (rowData) => (
        <span className="text-text">{`${format(
          parseISO(rowData.createdAt),
          'dd-MM-yyyy',
        )}`}
        </span>
      ),
    },
    {
      title: 'Url',
      sorting: false,
      field: 'url',
      cellStyle: {
        color: '#171819',
      },
    },
    {
      title: 'Status',
      render: (rowData) => (
        <h3
          style={{
            color: rowData.status ? '#239b55' : '#ff6b6b',
          }}
          className="font-semibold"
        >
          {rowData.status ? 'Ativo' : 'Desativado'}
        </h3>
      ),
    },
    {
      title: 'Ação',
      sorting: false,
      render: (rowData) => (
        <div className="flex flex-row">
          <button
            type="button"
            className="mr-2"
            onClick={() => {
              confirmAlert({
                title: `${rowData.status ? 'Desabilar' : 'Habilitar'} destaque`,
                message: 'Deseja confirmar essa ação?',
                buttons: [
                  {
                    label: 'Sim',
                    onClick: () => dispatch(
                      ClienteActions.updateDestaquesRequest(
                        { status: !rowData.status },
                        rowData._id,
                      ),
                    ),
                  },
                  {
                    label: 'Não',
                    onClick: () => {},
                  },
                ],
              });
            }}
          >
            {rowData.status ? (
              <MdVisibilityOff color="#ff6b6b" size={20} />
            ) : (
              <MdVisibility color="#77ffb0" size={20} />
            )}
          </button>
          <button
            type="button"
            onClick={() => {
              confirmAlert({
                title: 'Deletar destaque',
                message: 'Deseja confirmar essa ação? Ela é irreversivel',
                buttons: [
                  {
                    label: 'Sim',
                    onClick: () => dispatch(
                      ClienteActions.deleteDestaquesRequest(rowData._id),
                    ),
                  },
                  {
                    label: 'Não',
                    onClick: () => {},
                  },
                ],
              });
            }}
          >
            <BsFillTrash2Fill color="#fff" size={20} />
          </button>
        </div>
      ),
    },
  ];

  function handlePushNotification() {
    if (!pushData.title || !pushData.body) {
      return NotificationManager.warning(
        'Todos os campos são obrigatorios',
        'Envio de Push Notification',
      );
    }
    setPushData({
      title: '',
      body: '',
      data: {},
    });
    return dispatch(ClienteActions.sendPushRequest(pushData));
  }

  function handleCreateNovidade() {
    if (!destaqueFile) {
      return NotificationManager.warning(
        'O campo IMAGEM é obrigatorio',
        'Novo destaque',
      );
    }

    const fileData = new FormData();
    fileData.append('fileData', destaqueFile);
    dispatch(ClienteActions.createDestaquesRequest({ url: urlDestaque }, fileData));

    setDestaqueFile(null);
    setNewDestaqueModal(false);
    return setUrlDestaque('');
  }

  return (
    <div className="w-full h-screen flex flex-col md:flex-row items-center md:justify-between p-2 md:p-8">
      <div className="w-full md:w-2/6 md:h-full md:border-r md:border-text p-2 md:p-4 flex flex-row flex-col justify-start items-start">
        <h1 className="text-text text-2xl font-semibold">Enviar Push Notification</h1>
        <div>
          <h3 className="font-semibold text-text mt-4 text-lg">Escreva abaixo a mensagem que deseja enviar:</h3>
          <h4 className="font-thin text-text mb-4">Obs: Tenha em mente que o sistema de Push é limitado,
            escolha um TITULO CURTO e uma DESCRIÇÃO REDUZIDA.
          </h4>

          <TextInput
            placeholder="Titulo"
            mb="mb-2"
            value={pushData.title}
            onChange={({ target }) => setPushData({
              ...pushData,
              title: target.value,
            })}
          />
          <TextInput
            placeholder="Descrição"
            mb="mb-4"
            value={pushData.body}
            onChange={({ target }) => setPushData({
              ...pushData,
              body: target.value,
            })}
          />
          <ButtonComponent text="Enviar" loading={clienteData.loading} onClick={() => handlePushNotification()} />
        </div>
      </div>
      <div className="w-full md:w-4/6 md:h-full p-4">
        <h3 className="text-text text-2xl font-semibold">Gerenciar Destaques</h3>
        <button onClick={() => setNewDestaqueModal(!newDestaqueModal)} type="button" className="mt-4 px-4 rounded flex flex-col items-center justify-center bg-buttonPrimary">
          <h3 className="font-semibold text-textSecondary text-lg">Adicionar Destaque +</h3>
        </button>
        <h3 className="font-semibold text-text mt-4 text-lg">Gerencie os destaques na tabela a baixo:</h3>
        <DataTable columns={columns} data={destaques || []} title="" />
      </div>
      <ModalComponent
        openState={newDestaqueModal}
        closeAction={() => setNewDestaqueModal(!newDestaqueModal)}
      >
        <div className="p-2 md:p-4 w-full h-full flex flex-col items-start">
          <h3 className="text-text text-2xl font-semibold">Gerenciar Destaques</h3>
          <h3 className="font-semibold text-text mt-4 text-lg">Preencha a URL e selecione uma imagem para o novo destaque:</h3>
          <h3 className="font-thin text-text text-md">Tamanho recomendado 350(h)x600(w)</h3>
          <div className="w-1/2 mt-4">
            <div className="flex flex-row justify-center">
              <TextInput
                placeholder="URL"
                value={urlDestaque}
                onChange={({ target }) => setUrlDestaque(target.value)}
                icon={() => (<MdOutlineAddLink color="#171819" size={30} />)}
              />
              <button
                type="button"
                className="h-[50px] w-[50px] rounded flex flex-row items-center justify-center bg-buttonPrimary"
                onClick={() => {
                  workFileRef.current.click();
                }}
              >
                <RiImageAddFill color="#fff" size={25} />
              </button>
            </div>
            <input
              accept="image/png, image/jpeg, image/jpg"
              id="work-file"
              type="file"
              style={{
                display: 'none',
              }}
              onChange={(file) => {
                const fileData = file.target.files[0];
                const formatAccept = 'image/png, image/jpeg, image/jpg';

                if (formatAccept.indexOf(fileData.type) === -1) {
                  return NotificationManager.warning(
                    'Apenas arquivos JPG, JPEG e PNG são aceitos',
                    'Novo destaque',
                  );
                }

                return setDestaqueFile(file.target.files[0]);
              }}
              ref={workFileRef}
            />
            {destaqueFile && (
              <h3 className="font-semibold text-text text-md mt-2">Nome do arquivo: {destaqueFile.name}</h3>
            )}
            <button
              type="button"
              className="mt-8 px-4 py-2 rounded flex flex-row items-center justify-center bg-buttonPrimary"
              onClick={() => handleCreateNovidade()}
            >
              <h3 className="font-semibold text-textSecondary text-md mr-2">Continuar</h3>
              <MdArrowForwardIos color="#fff" size={25} />
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
