/* eslint-disable consistent-return */
import { put, call, select } from 'redux-saga/effects';
import MaquinasAction from '../ducks/maquinas';
import ClientesActions from '../ducks/cliente';
import api from '../../services/apiFenix';

export function* getMaquinasClient({ codclient, page }) {
  try {
    const { data } = yield call(
      api.get,
      `/rest/select/POPMAQUINA/?where=CODIGOCLIE="${codclient}".AND.EMPTY(CODOPEREXC)&fields=CODIGOCLIE,DtEntrega,DATAINCLUS,DTULTIMCON,DTVENCIGAR,Latitude,Longitude,LocalizacaoData,ENDCIDADE,ENDUF,HORIMETRO,MODELO,FROTA,SERIE,SLR&limit=50&page=${page}`,
    );
    yield put(MaquinasAction.getMaquinasClientSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(MaquinasAction.loadingCancel());
  }
}

export function* getSlr() {
  try {
    const { data } = yield call(
      api.get,
      '/rest/select/LINHAREPRE/?fields=SLR,DESCRICAO',
    );
    yield put(MaquinasAction.getSlrSuccess(data.docs));
  } catch (error) {
    console.log(error);
    yield put(MaquinasAction.loadingCancel());
  }
}

export function* getModelo({ slr, modelo, page }) {
  try {
    const url = slr
      ? `/rest/select/MODELOS/?fields=SLR,MODELO,DESCRICAO,FAMILIA&where=SLR="${slr}"&search=${modelo.toUpperCase()}&seFields=MODELO,DESCRICAO&limit=50&page=${page}`
      : `/rest/select/MODELOS/?fields=SLR,MODELO,DESCRICAO,FAMILIA&search=${modelo.toUpperCase()}&seFields=MODELO,DESCRICAO&limit=50&page=${page}`;

    const { data } = yield call(api.get, url);
    yield put(MaquinasAction.getModeloSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(MaquinasAction.loadingCancel());
  }
}

export function* getMaquinas({
  slr, modelo, serie, page,
}) {
  try {
    const whereClausure = (slrValue, modeloValue) => {
      if (slrValue && modeloValue) {
        return `where=SLR="${slrValue.toUpperCase()}".AND.MODELO="${modeloValue.toUpperCase()}"`;
      } if (slrValue) {
        return `where=SLR="${slrValue.toUpperCase()}"`;
      } if (modeloValue) {
        return `where=MODELO="${modeloValue.toUpperCase()}"`;
      }
    };

    const url = slr || modelo
      ? `/rest/select/POPMAQUINA/?${whereClausure(
        slr,
        modelo,
      )}&fields=SLR,MODELO,SERIE,HORIMETRO,ENDCIDADE,ENDUF,FROTA,DATAENTREGA,CODIGOCLIE&search=${serie.toUpperCase()}&seFields=SERIE&limit=50&page=${page}`
      : `/rest/select/POPMAQUINA/?fields=SLR,MODELO,SERIE,HORIMETRO,FROTA,ENDCIDADE,ENDUF,DATAENTREGA,CODIGOCLIE&search=${serie.toUpperCase()}&seFields=SERIE&limit=50&page=${page}`;

    const { data } = yield call(api.get, url);
    yield put(MaquinasAction.getMaquinaSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(MaquinasAction.loadingCancel());
  }
}

export function* setMaquinaSelect({ maquina }) {
  try {
    const clientSelected = yield select((store) => store.cliente.clientSelected);

    if (!clientSelected) {
      const codClient = maquina.CODIGOCLIE;
      const codClientFormat = codClient.length === 4 ? codClient.padStart(8, '0') : codClient;
      const url = `/rest/selecionaClientes/?id=${codClientFormat}`;
      const { data: response } = yield call(api.get, url);

      yield put(ClientesActions.setClientSelect(response.docs[0]));
    }

    yield put(MaquinasAction.setSelectMaquinaSuccess(maquina));
  } catch (error) {
    console.log(error);
    yield put(MaquinasAction.loadingCancel());
  }
}
