import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  row: {
    transition: '400ms background',
    cursor: 'pointer',
  },
  rowSelected: {
    transition: '400ms background',
    cursor: 'pointer',
    background: '#268eb5',
    color: 'white',
  },
  rowText: {},
  rowTextSelected: {
    color: 'white',
  },
  paginationRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowAlign: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default useStyles;
