/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import MaquinasAction from '../../../store/ducks/maquinas';

import TextInput from '../../../components/TextInput';
import ModalComponent from '../../../components/ModalComponent';
import DataTableCustom from '../../../components/DataTableCustom';

export default function MaquinaModelo() {
  const dispatch = useDispatch();
  const maquinasLoading = useSelector((store) => store.maquinas.loading);
  const maquinasResult = useSelector((store) => store.maquinas.modelosResult);
  const modeloSelecionado = useSelector((store) => store.maquinas.modeloSelecionado);
  const slrSelect = useSelector((store) => store.maquinas.slrSelecionada);

  const [modeloName, setModeloName] = useState('');
  const [modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    if (modeloSelecionado) {
      setModeloName(modeloSelecionado);
    }
  }, [modeloSelecionado]);

  const columnsModelo = [
    { title: 'Descrição', field: 'DESCRICAO' },
    { title: 'Familia', field: 'FAMILIA' },
    { title: 'Modelo', field: 'MODELO' },
    { title: 'SLR', field: 'SLR' },
  ];

  function handleSearchModelo(e) {
    if (e) {
      if (e.key === 'Enter') {
        setModalStatus(!modalStatus);
        return dispatch(
          MaquinasAction.getModeloRequest(slrSelect, modeloName, 1),
        );
      }
      return null;
    }

    setModalStatus(!modalStatus);
    return dispatch(MaquinasAction.getModeloRequest(slrSelect, modeloName, 1));
  }

  function selectModelo(maquina, e) {
    if (e) {
      if (e.key === 'Enter') {
        dispatch(MaquinasAction.setSelectModelo(maquina.MODELO));
        setModalStatus(!modalStatus);
        dispatch(MaquinasAction.setSelectSlr(maquina.SLR));
        dispatch(MaquinasAction.setSelectModelo(maquina.MODELO));
        return setModeloName(maquina.MODELO);
      }
      return null;
    }
    dispatch(MaquinasAction.setSelectSlr(maquina.SLR));
    dispatch(MaquinasAction.setSelectModelo(maquina.MODELO));
    setModalStatus(!modalStatus);
    return setModeloName(maquina.MODELO);
  }

  function handlePrevPageModelo() {
    return dispatch(
      MaquinasAction.getModeloRequest(
        slrSelect,
        modeloName,
        maquinasResult.prevPage,
      ),
    );
  }

  function handleNextPageModelo() {
    return dispatch(
      MaquinasAction.getModeloRequest(
        slrSelect,
        modeloName,
        maquinasResult.nextPage,
      ),
    );
  }

  return (
    <div className="w-full md:w-1/3 p-2">
      <h4 className="font-normal text-secondary mb-2">Modelo:</h4>
      <TextInput
        onFocus={(event) => event.stopPropagation()}
        onClick={(event) => event.stopPropagation()}
        onBlur={({ target }) => dispatch(MaquinasAction.setSelectModelo(target.value))}
        value={modeloName}
        onChange={({ target }) => setModeloName(target.value)}
        onKeyPress={(e) => handleSearchModelo(e)}
        placeholder=""
        iconRight={() => (
          <button type="button" onClick={() => handleSearchModelo()}>
            <FaSearch
              color="#003d70"
              size={20}
            />
          </button>
        )}
      />
      <ModalComponent
        openState={modalStatus}
        closeAction={() => setModalStatus(!modalStatus)}
        width="90%"
        height="90%"
      >
        <>
          <h3>
            Selecione um modelo
          </h3>
          {maquinasLoading ? (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <CircularProgress color="primary" />
            </div>
          ) : (
            <DataTableCustom
              column={columnsModelo}
              data={maquinasResult}
              selectionAction={selectModelo}
              prevAction={handlePrevPageModelo}
              nextAction={handleNextPageModelo}
            />
          )}
        </>
      </ModalComponent>
    </div>
  );
}
