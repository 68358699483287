import React from 'react';
// import { Container } from './styles';

export default function TextInput({
  placeholder, icon = false, iconRight = false, type = 'text', value, onChange, textarea, mb, className, ...props
}) {
  const getWidth = icon ? 'w-[90%]' : 'w-full';
  const getHeight = textarea ? 'h-[150px]' : 'h-[50px]';
  const getMargin = icon ? 'ml-2' : '';
  return (
    <div className={`w-full ${getHeight} ${mb} rounded flex flex-row items-center bg-textSecondary p-2 rounded-md`}>
      {icon && icon()}
      {textarea ? (
        <textarea
          className={`
            h-full
            ${getWidth}
            bg-transparent
            text-text
            ${getMargin}
            p-2
            border-textSecondary
            outline-none
            resize-none
            focus:border-text
            ${className}`}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      ) : (
        <input
          className={`
            h-full
            ${getWidth}
            bg-transparent
            text-text
            ${getMargin}
            p-2
            border-textSecondary
            outline-none
            focus:border-text
            ${className}`}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...props}
        />
      )}
      {iconRight && iconRight()}
    </div>
  );
}
