/* eslint-disable default-param-last */
import { format } from 'date-fns';
import { createReducer, createActions } from 'reduxsauce';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getLojasRequest: null,
  getLojasSuccess: ['lojas'],
  getOrigemContatoRequest: null,
  getOrigemContatoSuccess: ['data'],
  getHistoricoContatoRequest: null,
  getHistoricoContatoSuccess: ['data'],
  setOrigemContato: ['data'],
  setLojaSelect: ['data'],
  setOrigemContatoSelect: ['data'],
  setDataContato: ['data'],
});

export const UtilsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = {
  lojas: [],
  origemContato: null,
  historicoContato: null,
  origemContatoSelect: '',
  lojaSelect: '',
  dataContato: format(new Date(), 'yyyy-MM-dd'),
  loading: true,
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LOJAS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.GET_LOJAS_SUCCESS]: (state = INITIAL_STATE, { lojas }) => ({
    ...state,
    loading: false,
    lojas,
  }),
  [Types.GET_ORIGEM_CONTATO_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.GET_ORIGEM_CONTATO_SUCCESS]: (
    state = INITIAL_STATE,
    { data },
  ) => ({
    ...state,
    loading: false,
    origemContato: data,
  }),
  [Types.GET_HISTORICO_CONTATO_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.GET_HISTORICO_CONTATO_SUCCESS]: (
    state = INITIAL_STATE,
    { data },
  ) => ({
    ...state,
    loading: false,
    historicoContato: data,
  }),
  [Types.SET_ORIGEM_CONTATO_SELECT]: (
    state = INITIAL_STATE,
    { data },
  ) => ({
    ...state,
    loading: false,
    origemContatoSelect: data,
  }),
  [Types.SET_LOJA_SELECT]: (
    state = INITIAL_STATE,
    { data },
  ) => ({
    ...state,
    loading: false,
    lojaSelect: data,
  }),
  [Types.SET_DATA_CONTATO]: (
    state = INITIAL_STATE,
    { data },
  ) => ({
    ...state,
    loading: false,
    dataContato: data,
  }),
});
