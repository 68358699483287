/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import socketio from 'socket.io-client';
import UserActions from '../../store/ducks/user';
import ChatActions from '../../store/ducks/chat';
import ClienteActions from '../../store/ducks/cliente';

import HeaderComponent from '../../components/Header';
import Aplicativo from '../../pages/Aplicativo';
import Chamados from '../../pages/Chamados';
import NovoChamado from '../../pages/NovoChamado';
import Configuracoes from '../../pages/Configuracoes';
import ChamadosAtribuido from '../../pages/ChamadosAtribuido';
import Usuarios from '../../pages/Usuarios';

import NotificationAudio from '../../assets/sounds/notification.mp3';

// const socket = socketio('http://localhost:21124');

function Routes() {
  const dispatch = useDispatch();
  const chatsAtribuido = useSelector((store) => store.chat.chatsAtribuido);
  const chatDisconnect = useSelector((store) => store.chat.chatDisconnect);
  const userData = useSelector((store) => store.user.userData);
  const [alertSound, setAlertSound] = useState(null);
  const socket = useMemo(() => socketio('https://api.tecnoeste.net'), []);

  async function exitChatroom() {
    socket.emit('exit_chatroom', {
      chamadoId: chatDisconnect.chamadoId,
      user_id: userData._id,
      client: false,
    });
  }

  useEffect(() => {
    if (chatsAtribuido) {
      const chamados = chatsAtribuido.map((chat) => chat.chamadoId);
      socket.emit('join_chatroom', {
        chamados,
        user_id: userData._id,
        client: false,
      });
    }
  }, [chatsAtribuido]);

  useEffect(() => {
    if (chatDisconnect) {
      exitChatroom();
      dispatch(ChatActions.chatDisconnectSuccess());
    }
  }, [chatDisconnect]);

  useEffect(() => {
    const alertSoundLoading = new Audio(NotificationAudio);
    setAlertSound(alertSoundLoading);
    dispatch(UserActions.userDataRequest());
  }, []);

  useEffect(() => {
    if (socket) {
      // socket.on('notification', (notification) => console.log('notification', notification));
      socket.on('notification', (notification) => {
        dispatch(ChatActions.updateChatNotification(notification));
      });
      socket.on('new-chamado-by-client', () => {
        dispatch(ClienteActions.getChamadosRequest());
      });
      socket.on('handle-atribuicao-chamado', () => {
        dispatch(ClienteActions.getChamadosRequest());
        dispatch(ClienteActions.getChamadosAtribuidoRequest());
      });
    }
  }, []);

  return (
    <div className="w-full flex flex-col h-screen">
      <HeaderComponent />
      <div className="h-full w-full flex-1">
        <Switch>
          <Route exact path="/app/aplicativo" component={Aplicativo} />
          <Route exact path="/app/chamados" component={Chamados} />
          <Route exact path="/app/chamados-atribuido" component={ChamadosAtribuido} />
          <Route exact path="/app/novo-chamado" component={NovoChamado} />
          <Route exact path="/app/configuracoes" component={Configuracoes} />
          <Route exact path="/app/usuarios" component={Usuarios} />
          <Redirect from="/app" to="/app/chamados" />
        </Switch>
        {/* <Chat /> */}
      </div>
    </div>
  );
}

export default function App() {
  return <Routes />;
}
