/* eslint-disable no-unused-vars */
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UtilsActions from '../../../store/ducks/utils';
// import { Container } from './styles';

export default function DataContatoPicker() {
  const dispatch = useDispatch();
  const dataContato = useSelector((store) => store.utils.dataContato);
  return (
    <div className="w-full md:w-1/4 p-2">
      <h4 className="font-normal text-secondary mb-2">Data contato:</h4>
      <input
        type="date"
        value={dataContato}
        onChange={({ target }) => dispatch(UtilsActions.setDataContato(target.value))}
        className="
          h-[50px]
          w-full
          bg-textSecondary
          text-text
          p-2
          border-textSecondary
          outline-none
          focus:border-text
          bg-textSecondary
          rounded-md
        "
      />
    </div>
  );
}
