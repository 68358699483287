/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import ReactPlayer from 'react-player';
import { IoMdDocument, IoMdSearch } from 'react-icons/io';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { confirmAlert } from 'react-confirm-alert';
import serverUrl from '../../../utils/serverUrl';
// import { Container } from './styles';

export default function RenderMessage({ timeline }) {
  const position = (timelineData) => {
    if (timelineData === 'Employee') {
      return 'justify-end';
    }
    if (timelineData === 'system-app') {
      return 'justify-end';
    }
    if (timelineData === 'system-client') {
      return 'justify-start';
    }

    return 'justify-start';
  };

  const bgColor = (timelineData) => {
    if (timelineData === 'Employee') {
      return 'bg-blue-500';
    }
    if (timelineData === 'system-app') {
      return 'bg-blue-500';
    }
    if (timelineData === 'system-client') {
      return 'bg-secondary';
    }

    return 'bg-secondary';
  };

  const bgColorOrcamento = (status) => {
    if (status === 'aguardando') {
      return 'bg-amber-600';
    }
    if (status === 'aprovado') {
      return 'bg-emerald-600';
    }
    if (status === 'rejeitado') {
      return 'bg-red-700';
    }

    return 'bg-secondary';
  };

  const textAligin = (timelineData) => {
    if (timelineData === 'Employee') {
      return 'text-right';
    }
    if (timelineData === 'system-app') {
      return 'text-right';
    }
    if (timelineData === 'system-client') {
      return 'text-left';
    }

    return 'text-left';
  };

  return (
    <div className={`w-full flex flex-row ${position(timeline.createdRef || timeline.createdBy)} p-1`}>
      {timeline.messageFile && !timeline.title.includes('Orçamento -') && (
        <div className={`w-4/6 ${textAligin} flex flex-col p-2 rounded-md text-white leading-5`}>
          {timeline.fileData.mimetype === 'audio/m4a' && (
            <ReactPlayer
              url={`${serverUrl}/files/${timeline.fileData.fileName}`}
              controls
              width="100%"
              height="35px"
            />
          )}
          {timeline.fileData.mimetype.includes('image') && (
            <img
              src={`${serverUrl}/files/${timeline.fileData.fileName}`}
              alt="file message"
            />
          )}
          {timeline.fileData.mimetype.includes('video') && (

          <ReactPlayer
            url={`${serverUrl}/files/${timeline.fileData.fileName}`}
            controls
            width="100%"
            height="240px"
          />
          )}
          {timeline.fileData.mimetype.includes('application') && (
            <div className={`w-full ${textAligin(timeline.createdRef || timeline.createdBy)} ${bgColor(timeline.createdRef || timeline.createdBy)} p-2 rounded-md text-white`}>
              <a
                className="flex flex-row items-center"
                href={`${serverUrl}/files/${timeline.fileData.fileName}`}
                target="_blank"
                rel="noreferrer"
              >
                <IoMdDocument color="#fff" />
                <h3 className="ml">
                  {timeline.fileData.originalname}
                </h3>
              </a>
              {/* <h3 className={`${textAligin(timeline.createdRef || timeline.createdBy)} ${bgColor(timeline.createdRef || timeline.createdBy)} font-thin text-[10px]`}>
                {timeline.createdBy && timeline.createdRef && `@${timeline.createdBy} - `}
                {timeline.createdBy && !timeline.createdRef && '@sistema - '}
                {formatDistanceToNowStrict(
                  typeof timeline.registeredAt === 'string'
                    ? parseISO(timeline.registeredAt)
                    : timeline.registeredAt,
                  {
                    locale: ptBR,
                  },
                )}
              </h3> */}
            </div>
          )}
          {!timeline.fileData.mimetype.includes('application') && (
            <h3 className={`${textAligin(timeline.createdRef || timeline.createdBy)} ${bgColor(timeline.createdRef || timeline.createdBy)} font-thin text-[9.5px] rounded-b-md`}>
              -
            </h3>
          )}
          <h3 className={`${textAligin(timeline.createdRef || timeline.createdBy)} ${bgColor(timeline.createdRef || timeline.createdBy)} font-thin text-[9.5px] rounded-b-md`}>
            {timeline.createdBy && timeline.createdRef && `@${timeline.createdBy} - `}
            {timeline.createdBy && !timeline.createdRef && '@sistema - '}
            {formatDistanceToNowStrict(
              typeof timeline.registeredAt === 'string'
                ? parseISO(timeline.registeredAt)
                : timeline.registeredAt,
              {
                locale: ptBR,
              },
            )}
            {timeline.createdRef === 'Employee' && (
              <IoCheckmarkDoneSharp size={16} color={timeline.clienteOpen ? '#00FFAB' : '#fff'} />
            )}
          </h3>
        </div>
      )}
      {timeline.messageFile && timeline.orcData && timeline.title.includes('Orçamento -') ? (
        <div className={`w-2/6 py-4 ${textAligin(timeline.createdRef || timeline.createdBy)} ${bgColorOrcamento(timeline.orcData.status)} p-2 rounded-md text-white leading-5`}>
          <h3 className="text-lg font-semibold">{timeline.title}</h3>
          <div className="flex flex-row items-center justify-end w-full">
            <h3 className="text-sm font-semibold mr-2">Status:</h3>
            <h3 className="text-sm font-semibold">
              {timeline.orcData.status === 'aguardando' ? 'Aguardando aprovação' : timeline.orcData.status}
            </h3>
          </div>
          {timeline.fileData.mimetype.includes('image') && (
            <img
              src={`${serverUrl}/files/${timeline.fileData.fileName}`}
              alt="file message"
            />
          )}
          {timeline.fileData.mimetype.includes('application') && (
            <div className={`w-full ${textAligin(timeline.createdRef || timeline.createdBy)} ${bgColorOrcamento(timeline.orcData.status)} p-2 rounded-md text-white`}>
              <a
                className="flex flex-row items-center justify-end"
                href={`${serverUrl}/files/${timeline.fileData.fileName}`}
                target="_blank"
                rel="noreferrer"
              >
                <IoMdDocument color="#fff" />
                <h3 className="ml">
                  {timeline.fileData.originalname}
                </h3>
              </a>
            </div>
          )}
          {timeline.orcData.status === 'rejeitado' && (
            <button
              type="button"
              className="flex flex-row items-center justify-end w-full mt-4 mb-2"
              onClick={() => confirmAlert({
                title: 'Orçamento rejeitado pelo cliente',
                message: `Motivo: ${timeline.orcData.description}`,
                buttons: [
                  {
                    label: 'Voltar',
                    onClick: () => {},
                  },
                ],
              })}
            >
              <IoMdSearch size={25} color="#fff" />
              <h3>Ver motivo do cliente</h3>
            </button>
          )}
          <h3 className={`${textAligin(timeline.createdRef || timeline.createdBy)} ${bgColorOrcamento(timeline.orcData.status)} font-thin text-[10px]`}>
            {timeline.createdBy && timeline.createdRef && `@${timeline.createdBy} - `}
            {timeline.createdBy && !timeline.createdRef && '@sistema - '}
            {formatDistanceToNowStrict(
              typeof timeline.registeredAt === 'string'
                ? parseISO(timeline.registeredAt)
                : timeline.registeredAt,
              {
                locale: ptBR,
              },
            )}
          </h3>
        </div>
      ) : (
        <div className={`w-4/6 ${textAligin(timeline.createdRef || timeline.createdBy)} ${bgColor(timeline.createdRef || timeline.createdBy)} p-2 rounded-md text-white leading-5`}>
          <h3>{timeline.description}</h3>
          <h3 className={`${position(timeline.createdRef || timeline.createdBy)} bg-500-red font-thin text-[9.5px] flex flex-row items-center`}>
            {timeline.createdBy && timeline.createdRef && `@${timeline.createdBy} - `}
            {timeline.createdBy && !timeline.createdRef && '@sistema - '}
            {formatDistanceToNowStrict(
              typeof timeline.registeredAt === 'string'
                ? parseISO(timeline.registeredAt)
                : timeline.registeredAt,
              {
                locale: ptBR,
              },
            )}
            {timeline.createdRef === 'Employee' && (
              <IoCheckmarkDoneSharp size={16} color={timeline.clienteOpen ? '#00FFAB' : '#fff'} style={{ marginLeft: 5 }} />
            )}
          </h3>
        </div>
      )}
    </div>
  );
}
