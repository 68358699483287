/* eslint-disable no-shadow */
import React, { useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

function ScrollContainer({ style, scrollToBottom = false, ...props }) {
  const scrollRef = useRef();

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    // chatRef.current?.scrollToBottom();
    if (scrollToBottom) {
      scrollRef.current?.scrollToBottom();
    }
  }, [props.children]);

  return (
    <Scrollbars
      ref={scrollRef}
      style={{ paddingBottom: 200 }}
      renderTrackHorizontal={(props) => (
        <div
          {...props}
          className="track-horizontal"
          style={{ display: 'none' }}
        />
      )}
      renderThumbHorizontal={(props) => (
        <div
          {...props}
          className="thumb-horizontal"
          style={{ display: 'none' }}
        />
      )}
      renderTrackVertical={(props) => (
        <div
          {...props}
          className="track-vertical"
          style={{ display: 'none' }}
        />
      )}
      renderThumbVertical={(props) => (
        <div
          {...props}
          className="thumb-vertical"
          style={{ display: 'none' }}
        />
      )}
    >
      {props.children}
    </Scrollbars>
  );
}

export default ScrollContainer;
