/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

// import { Container } from './styles';

export default function ButtonComponent({
  text, onClick, border, loading = false,
}) {
  return (
    <div
      onClick={loading ? null : onClick}
      className={`w-full h-[50px] rounded flex flex-col items-center justify-center bg-buttonPrimary cursor-pointer ${border && 'border border-white'}`}
    >
      <h4 className="font-semibold text-textSecondary text-xl">{loading ? 'Carregando...' : text}</h4>
    </div>
  );
}
