/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
// import Cookies from 'universal-cookie';
import { put, call, select } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import ClienteActions from '../ducks/cliente';
import ChatActions from '../ducks/chat';
import MaquinasActions from '../ducks/maquinas';
import api from '../../services/api';
import apiFenix from '../../services/apiFenix';

export function* sendPush({ data }) {
  try {
    yield call(api.post, '/push', data);

    NotificationManager.success(
      'Notificação enviada com sucesso',
      'Envio de Push Notification',
    );

    return yield put(ClienteActions.sendPushSuccess());
  } catch (error) {
    console.log('error', error);
    NotificationManager.error(
      'Ocorreu um erro, tente novamente mais tarde',
      'Envio de Push Notification',
    );
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* getDestaques() {
  try {
    const { data: response } = yield call(api.get, '/novidade');

    return yield put(ClienteActions.getDestaquesSuccess(response));
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* updateDestaques({ data, id }) {
  try {
    yield call(api.put, `/novidade/${id}`, data);

    NotificationManager.success(
      'Destaque atualizada com sucesso',
      'Destaques',
    );

    yield put(ClienteActions.updateDestaquesSuccess());
    return yield call(getDestaques);
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* deleteDestaques({ id }) {
  try {
    yield call(api.delete, `/novidade/${id}`);

    NotificationManager.success(
      'Destaque deletada com sucesso',
      'Destaques',
    );

    yield put(ClienteActions.deleteDestaquesSuccess());
    return yield call(getDestaques);
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* createDestaques({ url, data }) {
  try {
    const { data: response } = yield call(api.post, '/novidade', url);

    yield call(api.post, `/file/${response.novidadeId}`, data);

    NotificationManager.success(
      'Destaque criada com sucesso',
      'Destaques',
    );

    yield put(ClienteActions.createDestaquesSuccess());
    return yield call(getDestaques);
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* getChamados() {
  try {
    const { data: response } = yield call(api.get, '/chamado');

    const formatResponse = response.length === 0 ? null : response;

    return yield put(ClienteActions.getChamadosSuccess(formatResponse));
  } catch (error) {
    console.log('cheg chamados', error);
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* getChamadosAtribuido() {
  try {
    const chatSelect = yield select((store) => store.chat.chatSelect);
    const { data: response } = yield call(api.get, '/chamado/atribuidos');

    const formatResponse = response.length === 0 ? null : response;

    if (formatResponse) {
      const formatToChat = response.map((chamado) => ({
        chamadoId: chamado._id,
        notification: 0,
        cliente_nome: chamado.clientData.nome,
        timeline: chamado.timeline,
      }));
      yield put(ChatActions.setChatsAtribuido(formatToChat));

      if (chatSelect) {
        const findChatSelect = formatToChat.filter(
          (chat) => chat.chamadoId === chatSelect.chamadoId,
        )[0];
        if (findChatSelect) {
          yield put(ChatActions.setChatSelect(findChatSelect));
        }
      }
    }

    return yield put(ClienteActions.getChamadosAtribuidoSuccess(formatResponse));
  } catch (error) {
    console.log('cheg chamados', error);
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* atribuirChamado({ id }) {
  try {
    yield call(api.put, `/chamado/atribuir-chamado/${id}`);

    yield put(ClienteActions.getChamadosAtribuidoRequest());
    yield put(ClienteActions.getChamadosRequest());

    NotificationManager.success(
      'Chamado atribuido com sucesso',
      'Chamado',
    );

    return yield put(ClienteActions.atribuirChamadoSuccess());
  } catch (error) {
    console.log('cheg chamados', error);
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* desatribuirChamado({ id }) {
  try {
    yield call(api.put, `/chamado/desaatribuir-chamado/${id}`);

    yield put(ClienteActions.getChamadosAtribuidoRequest());
    yield put(ClienteActions.getChamadosRequest());

    NotificationManager.success(
      'Chamado DESATRIBUIDO com sucesso',
      'Chamado',
    );

    return yield put(ClienteActions.desatribuirChamadoSuccess());
  } catch (error) {
    console.log('cheg chamados', error);
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* createChamados({ data }) {
  try {
    const navigationStore = yield select((store) => store.navigation.navigation);

    yield call(api.post, '/chamado', data);

    NotificationManager.success(
      'Chamado criado com sucesso',
      'Novo chamado',
    );

    navigationStore.push('/app/chamados');

    yield put(MaquinasActions.setSelectMaquinaSuccess(null));
    yield put(MaquinasActions.setSelectSlr(null));
    yield put(MaquinasActions.setSelectSerie(null));
    yield put(MaquinasActions.setSelectModelo(null));
    yield put(ClienteActions.setClientSelect(null));

    return yield put(ClienteActions.createChamadosSuccess());
  } catch (error) {
    console.log('create chamados', error);
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* updateChamados({ data, id }) {
  try {
    yield call(api.put, `/chamado/${id}`, data);

    yield put(ClienteActions.getChamadosRequest());

    NotificationManager.success(
      'Chamado atualizado com sucesso',
      'Chamado',
    );

    return yield put(ClienteActions.updateChamadosSuccess());
  } catch (error) {
    NotificationManager.error(
      'Serviço indisponivel no momento, tente novamente mais tarde',
      'Chamado',
    );
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* getChamadoStatus() {
  try {
    const { data: response } = yield call(api.get, '/status-chamado');

    return yield put(ClienteActions.getChamadoStatusSuccess(response));
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* getChamadoVincStatus({ id }) {
  try {
    const { data: response } = yield call(api.get, `/status-chamado/${id}`);

    return yield put(ClienteActions.getChamadoVincStatusSuccess(response));
  } catch (error) {
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* createChamadoStatus({ data }) {
  try {
    yield call(api.post, '/status-chamado/newsub', data);
    yield put(ClienteActions.getChamadoVincStatusRequest(data.vinculado));
    yield put(ClienteActions.getChamadoStatusRequest());

    NotificationManager.success(
      'Status Criado com sucesso',
      'Chamado Status',
    );
    return yield put(ClienteActions.createChamadoStatusSuccess());
  } catch (error) {
    console.log('error', error);
    NotificationManager.error(
      'Falha na criação - Cod de Status já utilizado',
      'Chamado Status',
    );
    return yield put(ClienteActions.loadingCancel());
  }
}

export function* searchClient({ name, page, query }) {
  try {
    const formatedName = parseInt(name);
    const isNumber = isNaN(formatedName);

    if (query) {
      const codClient = name.length === 4 ? name.padStart(8, '0') : name;
      const url = `/rest/selecionaClientes/?id=${codClient}`;
      const response = yield call(apiFenix.get, url);
      return yield put(ClienteActions.searchClientSuccess(response.data));
    }

    const url = () => {
      if (isNumber) {
        return `/rest/selecionaClientes/?search=${name.toUpperCase()}&seFields=NOME,FANTASIA&limit=50&page=${page}`;
      } if (!isNumber && formatedName.length === 8) {
        return `/rest/selecionaClientes/?search=${name.toUpperCase()}&seFields=CGCCPF,CODIGOCLIE&limit=50&page=${page}`;
      } if (!isNumber && formatedName.length > 8) {
        return `/rest/selecionaClientes/?search=${name.toUpperCase()}&seFields=CGCCPF&limit=50&page=${page}`;
      }
      return `/rest/selecionaClientes/?search=${name.toUpperCase()}&seFields=NOME,FANTASIA,CGCCPF,CODIGOCLIE&limit=50&page=${page}`;
    };

    const { data } = yield call(apiFenix.get, url());
    console.log(data);
    yield put(ClienteActions.searchClientSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

export function* searchClientInfo({ payload }) {
  try {
    const url = `/rest/select/CLIENFORNE/?where=CODIGOCLIE=="${payload.CODIGOCLIE}"&fields=FATORMUPEC,OBSVENDEQU,recno,OBSVENDFIN,OBSVENDPEC,OBSVENDSER,CMAXDESCPE,CMAXDESCSE`;
    const urlContatos = `/rest/select/CLICONTUNI/?where=CGCCPF="${payload.CGCCPF}"`;

    const { data } = yield call(apiFenix.get, url);
    const contatos = yield call(apiFenix.get, urlContatos);
    yield put(
      ClienteActions.searchClientInfoSuccess(data.docs[0], contatos.data.docs),
    );
  } catch (error) {
    console.log(error);
  }
}
