/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import ClientesAction from '../../../store/ducks/cliente';

import TextInput from '../../../components/TextInput';
import ModalComponent from '../../../components/ModalComponent';
import DataTableCustom from '../../../components/DataTableCustom';

export default function SelectClient() {
  const dispatch = useDispatch();
  const clienteLoading = useSelector((store) => store.cliente.loading);
  const clientesData = useSelector((store) => store.cliente.clientsSearch);
  const clientSelected = useSelector((store) => store.cliente.clientSelected);

  const [clientName, setClientName] = useState('');
  const [modalStatus, setModalStatus] = useState(false);

  const columns = [
    { title: 'Cod. Cliente', field: 'CODIGOCLIE' },
    { title: 'Nome', field: 'NOME' },
    { title: 'Fantasia', field: 'FANTASIA' },
    { title: 'CNPJ/CPF', field: 'CGCCPF' },
    { title: 'Cidade', field: 'ENDCIDADE' },
    { title: 'Estado', field: 'ENDUF' },
  ];

  useEffect(() => {
    if (clientSelected) {
      setClientName(clientSelected.NOME);
    }
  }, [clientSelected]);

  function handleSearchClient(e) {
    if (e) {
      console.log('e.key', e.key);
      if (e.key === 'Enter') {
        setModalStatus(true);
        const page = 1;

        return dispatch(ClientesAction.searchClientRequest(clientName, page));
      }
      return null;
    }

    setModalStatus(true);
    const page = 1;

    return dispatch(ClientesAction.searchClientRequest(clientName, page));
  }

  function selectClient(client, e) {
    if (e) {
      if (e.key === 'Enter') {
        dispatch(ClientesAction.setClientSelect(client));
        return setModalStatus(!modalStatus);
      }
      return null;
    }

    dispatch(ClientesAction.setClientSelect(client));
    return setModalStatus(!modalStatus);
  }

  function handleNextPage() {
    return dispatch(
      ClientesAction.searchClientRequest(
        clientName,
        clientesData.nextPage,
      ),
    );
  }

  function handlePrevPage() {
    return dispatch(
      ClientesAction.searchClientRequest(
        clientName,
        clientesData.prevPage,
      ),
    );
  }

  const handlerClientQuery = useCallback((e) => {
    setClientName(e.target.value);
  }, []);

  return (
    <div className="w-full md:w-1/4 p-2">
      <h4 className="font-normal text-secondary mb-2">Pesquisar cliente:</h4>
      <TextInput
        onFocus={(event) => event.stopPropagation()}
        onClick={(event) => event.stopPropagation()}
        value={clientName}
        onChange={handlerClientQuery}
        onKeyPress={(e) => handleSearchClient(e)}
        placeholder=""
        iconRight={() => (
          <button type="button" onClick={() => handleSearchClient()}>
            <FaSearch
              color="#003d70"
              size={20}
            />
          </button>
        )}
      />
      <ModalComponent
        openState={modalStatus}
        closeAction={() => setModalStatus(!modalStatus)}
        width="90%"
        height="90%"
      >
        <>
          <h3>
            Selecione um cliente
          </h3>
          {clienteLoading ? (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <CircularProgress color="primary" />
            </div>
          ) : (
            <DataTableCustom
              column={columns}
              data={clientesData}
              selectionAction={selectClient}
              prevAction={handlePrevPage}
              nextAction={handleNextPage}
            />
          )}
        </>
      </ModalComponent>
    </div>
  );
}
