/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { MdAttachFile } from 'react-icons/md';
import { FaFileImport } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';
import { confirmAlert } from 'react-confirm-alert';

import RenderMessage from './RenderMessage';
import ScrollContainer from '../../components/ScrollComponent';
import ClienteActions from '../../store/ducks/cliente';
import ChatActions from '../../store/ducks/chat';
import InputFileTypeData from '../../utils/inputFileTypeAccept';
import ModalComponent from '../../components/ModalComponent';
import TextInput from '../../components/TextInput';
// import { Container } from './styles';

export default function ChamadosAtribuido() {
  const attachRef = useRef(null);
  const dispatch = useDispatch();
  const userData = useSelector((store) => store.user.userData);
  const chatsAtribuido = useSelector((store) => store.chat.chatsAtribuido);
  const chatSelect = useSelector((store) => store.chat.chatSelect);
  // console.log('chatsAtribuido', chatsAtribuido);

  const [msgText, setMsgText] = useState('');
  const [orcamentoFileStatus, setOrcamentoFileStatus] = useState(false);
  const [orcamentoFileData, setOrcamentoFileData] = useState({
    numOrc: '',
    orcPecas: true,
    file: null,
  });

  useEffect(() => {
    dispatch(ClienteActions.getChamadosAtribuidoRequest());
  }, []);

  function handleBadgeName(clienteName) {
    const splitName = clienteName.split(' ');
    if (splitName.length > 1) {
      return `${splitName[0][0]}${splitName[1][0]}`;
    }
    return `${splitName[0][0]}${splitName[0][1]}`;
  }

  function sendMessage() {
    const messageData = {
      message: msgText,
      messageFile: false,
      title: 'Mensagem',
      createdBy: userData.nome,
      createdRef: 'Employee',
      createdByEmployeeId: userData._id,
      registeredAt: new Date(),
      createdAt: new Date(),
    };
    setMsgText('');
    return dispatch(ChatActions.sendMessageRequest(messageData, chatSelect.chamadoId));
  }

  function validadeFileType(file) {
    const formatType = (
      file.type.includes('video')
      || file.type.includes('audio'))
      ? file.type.split('/')[0]
      : file.type;

    const validadeTypesArray = InputFileTypeData.indexOf(formatType) > -1;

    if (validadeTypesArray) {
      const bytesToMegaBytes = (bytes) => bytes / (1024 ** 2);
      if (bytesToMegaBytes(file.size) < 50) {
        return true;
      }
      NotificationManager.warning(
        'O tamanho do arquiv é maior que 50mb, tente novamente',
        'Envio de anexo',
      );

      return null;
    }

    NotificationManager.warning(
      'Formato selecionado não é permitido, tente novamente',
      'Envio de anexo',
    );

    return null;
  }

  function sendAttach(file) {
    const checkValidations = validadeFileType(file);

    if (checkValidations) {
      const messageData = {
        title: 'Mensagem',
        message: msgText,
        messageFile: true,
        fileData: file,
        createdBy: userData.nome,
        createdRef: 'Employee',
        createdByEmployeeId: userData._id,
        registeredAt: new Date(),
      };

      return dispatch(ChatActions.sendMessageRequest(messageData, chatSelect.chamadoId, true));
    }

    return null;
  }

  function sendOrcamento() {
    // return NotificationManager.error(
    //   'O formato do arquivo informado não é permitido',
    //   'Envio de orçamento',
    // );
    if (!orcamentoFileData.numOrc) {
      return NotificationManager.error(
        'Por favor digite o numero do orçamento',
        'Envio de orçamento',
      );
    }

    if (!orcamentoFileData.file) {
      return NotificationManager.error(
        'Por favor selecione um arquivo para envio',
        'Envio de orçamento',
      );
    }
    const checkValidations = validadeFileType(orcamentoFileData.file);

    if (checkValidations) {
      const messageData = {
        title: `Orçamento - ${orcamentoFileData.numOrc}`,
        message: msgText,
        messageFile: true,
        fileData: orcamentoFileData.file,
        createdBy: userData.nome,
        createdRef: 'Employee',
        createdByEmployeeId: userData._id,
        orcamento: true,
        orcamentoData: {
          numOrc: orcamentoFileData.numOrc,
          type: orcamentoFileData.orcPecas ? 'pecas' : 'servicos',
        },
        registeredAt: new Date(),
      };

      dispatch(ChatActions.sendMessageRequest(messageData, chatSelect.chamadoId, true, true));
      // setOrcamentoFileData({
      //   numOrc: '',
      //   orcPecas: true,
      //   file: null,
      // });

      // return setOrcamentoFileStatus(false);
    }

    return NotificationManager.error(
      'O formato do arquivo informado não é permitido',
      'Envio de orçamento',
    );
  }

  function handleOrcFileData(value, name) {
    return setOrcamentoFileData({
      ...orcamentoFileData,
      [name]: value,
    });
  }

  function handleAttach() {
    return confirmAlert({
      title: 'Envio de anexo',
      message: 'O documento a ser enviado, é um orçamento?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => setOrcamentoFileStatus(true),
        },
        {
          label: 'Não',
          onClick: () => {
            attachRef.current.click();
          },
        },
      ],
    });
  }

  return (
    <div className="h-full w-full flex flex-row items-center">
      <div className="h-full w-[28%] bg-secondary p-2">
        {chatsAtribuido && chatsAtribuido.map((chamado) => (
          <button
            key={chamado._id}
            type="button"
            className={`
              flex
              flex-row
              items-center
              justify-start
              w-full
              p-2
              border
              mb-4
              ${chatSelect && (chatSelect.chamadoId === chamado.chamadoId) && 'bg-textSecondary'}
            `}
            // onClick={() => setchatSelect(chamado)}
            onClick={() => dispatch(ChatActions.setChatSelect(chamado))}
          >
            <div className="w-[10%]">
              <h3
                className={`
                  w-[40px]
                  h-[40px]
                  rounded-[100px]
                  flex
                  items-center
                  justify-center
                  mt-2
                  font-bold
                  uppercase
                  ${chatSelect && (chatSelect.chamadoId === chamado.chamadoId)
                  ? 'bg-secondary text-textSecondary'
                  : 'text-secondary bg-textSecondary'
                  }
                `}
              >
                {handleBadgeName(chamado.cliente_nome)}
              </h3>
            </div>
            <div className="w-[90%]">
              <h3
                className={`
                  font-semibold
                  text-left
                  ml-2
                  text-textSecondary
                  ${chatSelect && (chatSelect.chamadoId === chamado.chamadoId)
                  ? 'text-secondary'
                  : 'text-textSecondary'
                  }
                `}
              >
                {chamado.cliente_nome}
              </h3>
              <h3
                className={`
                  text-left
                  max-w-[90%]
                  font-thin
                  text-xs
                  leading-3
                  truncate
                  ml-2
                  ${chatSelect && (chatSelect.chamadoId === chamado.chamadoId)
                  ? 'text-secondary'
                  : 'text-textSecondary'
                    }
                `}
              >
                {chamado.timeline.at(-1).messageFile ? 'Anexo' : chamado.timeline.at(-1).description}
              </h3>
            </div>
          </button>
        ))}
      </div>
      <div className="h-full w-[82%] p-2">
        {chatSelect ? (
          <div className="h-full w-full">
            <div className="h-[92%]">
              <ScrollContainer scrollToBottom>
                {chatSelect.timeline.map((timeline) => (
                  <RenderMessage timeline={timeline} key={timeline._id} />
                ))}
              </ScrollContainer>
            </div>
            <div className="flex flex-row items-center justify-between w-full h-[8%]">
              <input
                className={`
                  h-full
                  w-[95%]
                  pl-2
                  bg-gray-200
                  rounded-md
                  text-black
                  border-textSecondary
                  outline-none
                  focus:border-text
                `}
                placeholder="Aa"
                value={msgText}
                onChange={({ target }) => setMsgText(target.value)}
                onKeyPress={({ key }) => key === 'Enter' && sendMessage()}
              />
              {msgText ? (
                <button
                  type="button"
                  className="w-[5%] h-full rounded-[50px] flex items-center justify-center"
                  onClick={() => sendMessage()}
                >
                  <IoMdSend color="#003d70" size={25} />
                </button>
              ) : (
                <button
                  type="button"
                  className="w-[5%] h-full rounded-[50px] flex items-center justify-center"
                  // onClick={() => audioStart()}
                  onClick={() => {
                    handleAttach();
                  }}
                >
                  <MdAttachFile color="#003d70" size={25} />
                </button>
              )}
              <input
                accept={`
                  application/pdf,
                  application/vnd.ms-excel,
                  application/vnd.ms-powerpoint,
                  application/vnd.oasis.opendocument.text,
                  application/vnd.openxmlformats-officedocument.presentationml.presentation,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                  application/xml,
                  text/csv,
                  image/jpeg,
                  image/png,
                  video/*,
                  audio/*,
                `}
                id="complementary-work-file"
                type="file"
                style={{
                  display: 'none',
                }}
                onChange={(file) => sendAttach(file.target.files[0])}
                ref={attachRef}
              />
            </div>
          </div>
        ) : (
          <div className="h-full w-full flex items-center justify-center">
            <h3 className="text-secondary font-semibold">
              Selecione um chamado
            </h3>
          </div>
        )}
      </div>
      <ModalComponent
        height="50%"
        width="35%"
        openState={orcamentoFileStatus}
        closeAction={() => setOrcamentoFileStatus(!orcamentoFileStatus)}
      >
        <div className="p-2 md:p-4 w-full h-full flex flex-col items-start">
          <h3 className="text-secondary text-xl font-semibold">Enviar orçamento</h3>
          <div className="w-full mt-4">
            <h4 className="font-semibold text-secondary mb">Tipo de orçamento:</h4>
            <div className="flex flex-row items-center">
              <div className="flex flex-row items-center">
                <input
                  type="checkbox"
                  checked={orcamentoFileData.orcPecas}
                  onChange={() => handleOrcFileData(true, 'orcPecas')}
                />
                <h4 className="font-semithin text-text">Peças</h4>
              </div>
              <div className="flex flex-row items-center ml-4">
                <input
                  type="checkbox"
                  checked={!orcamentoFileData.orcPecas}
                  onChange={() => handleOrcFileData(false, 'orcPecas')}
                />
                <h4 className="font-semithin text-text">Serviços</h4>
              </div>
            </div>
          </div>
          <div className="w-full mt-2">
            <h4 className="font-semibold text-secondary mb-2">Numero do orçamento</h4>
            <TextInput
              placeholder=""
              value={orcamentoFileData.numOrc}
              onChange={({ target }) => handleOrcFileData(target.value, 'numOrc')}
            />
            {orcamentoFileData.file && (
              <h3 className="font-semibold text-text text-md mt-2">Nome do arquivo: {orcamentoFileData.file.name}</h3>
            )}
          </div>
          <div className="w-full">
            <button
              type="button"
              className="mt-8 w-full py-2 rounded flex flex-row items-center justify-center border-2 border-buttonPrimary"
              onClick={() => attachRef.current.click()}
            >
              <h3 className="font-semibold text-buttonPrimary text-md mr-2">Selecionar orçamento</h3>
              <FaFileImport size={25} color="#003d70" />
            </button>
            <button
              type="button"
              className="mt-2 w-full py-2 rounded flex flex-row items-center justify-center bg-buttonPrimary"
              onClick={() => sendOrcamento()}
            >
              <h3 className="font-semibold text-textSecondary text-md mr-2">Enviar</h3>
              <IoMdSend size={25} color="#E8E8E9" />
            </button>
            <input
              accept={`
                application/pdf,
                application/vnd.ms-excel,
                application/vnd.ms-powerpoint,
                application/vnd.oasis.opendocument.text,
                application/vnd.openxmlformats-officedocument.presentationml.presentation,
                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                application/xml,
                text/csv,
                image/jpeg,
                image/png,
                video/*,
                audio/*,
              `}
              id="complementary-work-file"
              type="file"
              style={{
                display: 'none',
              }}
              onChange={(file) => handleOrcFileData(file.target.files[0], 'file')}
              ref={attachRef}
            />
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
