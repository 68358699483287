/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import styles from './styles';

export default function DateRow({
  data,
  indexRow,
  rowSelected,
  columns,
  rowAction,
}) {
  const classes = styles();
  const rowRef = React.useRef();

  React.useEffect(() => {
    if (rowRef.current && rowSelected === indexRow) {
      rowRef.current.focus();
    }
  }, [rowSelected]);

  return (
    <TableRow
      tabIndex="1"
      onKeyPress={(e) => {
        rowAction(data, e);
      }}
      onClick={() => {
        rowAction(data);
      }}
      ref={rowRef}
      className={rowSelected === indexRow ? classes.rowSelected : classes.row}
    >
      {columns.map((column, columnIndex) => (
        <TableCell
          key={columnIndex}
          className={
            rowSelected === indexRow ? classes.rowTextSelected : classes.rowText
          }
        >
          {data[column.field]}
        </TableCell>
      ))}
    </TableRow>
  );
}
