/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getTokenFenix } from './auth';

const apiFenix = axios.create({
  // baseURL: 'https://api.tecnoeste.net/p21124',
  // baseURL: 'http://localhost:21124',
  baseURL: 'https://fenixapi.tecnoeste.net',
});

apiFenix.interceptors.request.use(async (config) => {
  const token = getTokenFenix();

  if (token) {
    config.headers.Authorization = `${token}`;
  }

  return config;
});

export default apiFenix;
