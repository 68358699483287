/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import MaquinasAction from '../../../store/ducks/maquinas';

import TextInput from '../../../components/TextInput';
import ModalComponent from '../../../components/ModalComponent';
import DataTableCustom from '../../../components/DataTableCustom';

export default function MaquinaModelo() {
  const dispatch = useDispatch();
  const maquinasLoading = useSelector((store) => store.maquinas.loading);
  const maquinasResult = useSelector((store) => store.maquinas.maquinasResult);
  const slrSelect = useSelector((store) => store.maquinas.slrSelecionada);
  const modeloSelecionado = useSelector((store) => store.maquinas.modeloSelecionado);
  const maquinaModalStatus = useSelector((store) => store.maquinas.maquinaModalStatus);

  const [serieName, setSerieName] = useState('');

  const columnsMaquinas = [
    { title: 'Cod. Client', field: 'CODIGOCLIE' },
    { title: 'SLR', field: 'SLR' },
    { title: 'Modelo', field: 'MODELO' },
    { title: 'Frota', field: 'FROTA' },
    { title: 'Serie', field: 'SERIE' },
    { title: 'Horimetro', field: 'HORIMETRO' },
    { title: 'Cidade', field: 'ENDCIDADE' },
    { title: 'Estado', field: 'ENDUF' },
  ];

  function handleSearchMaquina(e) {
    if (e) {
      if (e.key === 'Enter') {
        dispatch(MaquinasAction.setMaqModalStatus(!maquinaModalStatus));

        return dispatch(
          MaquinasAction.getMaquinaRequest(slrSelect, modeloSelecionado, serieName, 1),
        );
      }
      return null;
    }

    dispatch(MaquinasAction.setMaqModalStatus(!maquinaModalStatus));
    return dispatch(
      MaquinasAction.getMaquinaRequest(slrSelect, modeloSelecionado, serieName, 1),
    );
  }

  function selectMaquina(maquina, e) {
    if (e) {
      if (e.key === 'Enter') {
        dispatch(MaquinasAction.setMaqModalStatus(!maquinaModalStatus));
        setSerieName(maquina.SERIE);
        dispatch(MaquinasAction.setSelectMaquinaRequest(maquina));
        dispatch(MaquinasAction.setSelectSlr(maquina.SLR));
        dispatch(MaquinasAction.setSelectSerie(maquina.SERIE));
        return dispatch(MaquinasAction.setSelectModelo(maquina.MODELO));
      }
      return null;
    }
    dispatch(MaquinasAction.setMaqModalStatus(!maquinaModalStatus));
    setSerieName(maquina.SERIE);
    dispatch(MaquinasAction.setSelectMaquinaRequest(maquina));
    dispatch(MaquinasAction.setSelectSlr(maquina.SLR));
    dispatch(MaquinasAction.setSelectSerie(maquina.SERIE));
    return dispatch(MaquinasAction.setSelectModelo(maquina.MODELO));
  }

  function handleNextPageMaquina() {
    return dispatch(
      MaquinasAction.getMaquinaRequest(
        slrSelect,
        modeloSelecionado,
        serieName,
        maquinasResult.nextPage,
      ),
    );
  }
  function handlePrevPageMaquina() {
    return dispatch(
      MaquinasAction.getMaquinaRequest(
        slrSelect,
        modeloSelecionado,
        serieName,
        maquinasResult.prevPage,
      ),
    );
  }

  return (
    <div className="w-full md:w-1/3 p-2">
      <h4 className="font-normal text-secondary mb-2">Serie:</h4>
      <TextInput
        value={serieName}
        onFocus={(event) => event.stopPropagation()}
        onClick={(event) => event.stopPropagation()}
        onBlur={({ target }) => dispatch(MaquinasAction.setSelectSerie(target.value))}
        onChange={({ target }) => setSerieName(target.value)}
        onKeyPress={(e) => handleSearchMaquina(e)}
        placeholder=""
        iconRight={() => (
          <button type="button" onClick={() => handleSearchMaquina()}>
            <FaSearch
              color="#003d70"
              size={20}
            />
          </button>
        )}
      />
      <ModalComponent
        openState={maquinaModalStatus}
        closeAction={() => dispatch(MaquinasAction.setMaqModalStatus(!maquinaModalStatus))}
        width="90%"
        height="90%"
      >
        <>
          <h3>
            Selecione uma maquina
          </h3>
          {maquinasLoading ? (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <CircularProgress color="primary" />
            </div>
          ) : (
            <DataTableCustom
              column={columnsMaquinas}
              data={maquinasResult}
              selectionAction={selectMaquina}
              prevAction={handlePrevPageMaquina}
              nextAction={handleNextPageMaquina}
            />
          )}
        </>
      </ModalComponent>
    </div>
  );
}
