import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UtilsActions from '../../../store/ducks/utils';
// import { Container } from './styles';

export default function LojaSelect() {
  const dispatch = useDispatch();
  const lojasData = useSelector((store) => store.utils.lojas);
  const lojaSelect = useSelector((store) => store.utils.lojaSelect);
  return (
    <div className="w-full md:w-1/4 p-2">
      <h4 className="font-normal text-secondary mb-2">Escolha uma loja:</h4>
      <select
        value={lojaSelect}
        onChange={({ target }) => dispatch(UtilsActions.setLojaSelect(target.value))}
        className="
          h-[50px]
          w-full
          bg-textSecondary
          text-text
          p-2
          border-textSecondary
          outline-none
          focus:border-text
          bg-textSecondary
          rounded-md
        "
      >
        <option value="-">-</option>
        {lojasData && lojasData.map((loja) => (
          <option value={loja.GEF} key={loja.GEF}>
            {loja.FILIAL} ({loja.GEF})
          </option>
        ))}
      </select>
    </div>
  );
}
