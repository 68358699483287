/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import ClientesAction from '../../../store/ducks/cliente';
import TextInput from '../../../components/TextInput';

// import { Container } from './styles';

export default function DadosChamado() {
  const dispatch = useDispatch();
  const userData = useSelector((store) => store.user.userData);
  const maqSelected = useSelector((store) => store.maquinas.maquinaSelecionada);
  const slrSelecionada = useSelector((store) => store.maquinas.slrSelecionada);
  const serieSelecionada = useSelector((store) => store.maquinas.serieSelecionada);
  const modeloSelecionado = useSelector((store) => store.maquinas.modeloSelecionado);
  const clientSelected = useSelector((store) => store.cliente.clientSelected);
  const dataContato = useSelector((store) => store.utils.dataContato);
  const lojaSelect = useSelector((store) => store.utils.lojaSelect);
  const origemContatoSelect = useSelector((store) => store.utils.origemContatoSelect);
  const [dataChamado, setDataChamado] = useState({
    codError: '',
    localizacao: '',
    descricao: '',
    maquinaParada: false,
  });

  function handleDataChamado(value, name) {
    return setDataChamado({
      ...dataChamado,
      [name]: value,
    });
  }

  function createNewChamado() {
    if (!dataChamado.codError && !dataChamado.localizacao) {
      return confirmAlert({
        title: 'Novo chamado',
        message: 'Por favor, preencha: Cod Erro ou Localização',
        buttons: [
          {
            label: 'Voltar',
            onClick: () => {},
          },
        ],
      });
    }

    if (!slrSelecionada || !serieSelecionada || !modeloSelecionado) {
      return confirmAlert({
        title: 'Novo chamado',
        message: 'Todos os campos da maquina são obrigatorios: SLR, MODELO E SERIE',
        buttons: [
          {
            label: 'Voltar',
            onClick: () => {},
          },
        ],
      });
    }

    if (!maqSelected && !clientSelected) {
      return confirmAlert({
        title: 'Novo chamado',
        message: 'Maquina não pesquisada, nesse caso um cliente é obrigatorio',
        buttons: [
          {
            label: 'Voltar',
            onClick: () => {},
          },
        ],
      });
    }

    const customData = {
      ...dataChamado,
      keyComposta: `${maqSelected.SLR}-${maqSelected.MODELO}-${maqSelected.SERIE}`,
      origemContato: origemContatoSelect,
      contatoDate: dataContato,
      loja: lojaSelect,
      clientData: {
        cgccpf: clientSelected.CGCCPF,
        codigoclie: clientSelected.CODIGOCLIE,
        endbairro: clientSelected.ENDBAIRRO,
        endcidade: clientSelected.ENDCIDADE,
        endcodcida: clientSelected.ENDCODCIDA,
        enduf: clientSelected.ENDUF,
        e_mail: clientSelected.E_MAIL,
        fantasia: clientSelected.FANTASIA,
        nome: clientSelected.NOME,
        telefone1: clientSelected.TELEFONE1,
        recno: clientSelected.recno,
      },
      maqData: maqSelected ? {
        CodigoClie: maqSelected.CODIGOCLIE,
        DataInclus: maqSelected.DATAINCLUS,
        DtUltimCon: maqSelected.DTULTIMCON,
        DtVenciGar: maqSelected.DTVENCIGAR,
        DtEntrega: maqSelected.DtEntrega,
        EndCidade: maqSelected.ENDCIDADE,
        EndUF: maqSelected.ENDUF,
        Frota: maqSelected.FROTA,
        Horimetro: maqSelected.HORIMETRO,
        Latitude: maqSelected.Latitude,
        LocalizacaoData: maqSelected.LocalizacaoData,
        Longitude: maqSelected.Longitude,
        Modelo: maqSelected.MODELO,
        Serie: maqSelected.SERIE,
        SLR: maqSelected.SLR,
      } : {
        Modelo: modeloSelecionado,
        Serie: serieSelecionada,
        SLR: slrSelecionada,
      },
      maqSlr: maqSelected.SLR,
      maqModelo: maqSelected.MODELO,
      maqSerie: maqSelected.SERIE,
      cliente: clientSelected.CODIGOCLIE,
      proprietario: maqSelected.CODIGOCLIE,
      createdByEmployeeId: userData._id,
      createdByIdRef: 'Employee',
      registeredAt: new Date(),
    };

    return dispatch(ClientesAction.createChamadosRequest(customData));
  }

  return (
    <div className="flex flex-col items-center justify-between w-full px-4 mt-4 mb-[300px]">
      <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-between">
        <div className="w-full md:w-1/4 md:pr-2">
          <h4 className="font-normal text-secondary mb-2">Cod. Erro:</h4>
          <TextInput
            value={dataChamado.codError}
            onChange={({ target }) => handleDataChamado(target.value, 'codError')}
            placeholder=""
          />
        </div>
        <div className="w-full md:w-2/4 md:pr-2">
          <h4 className="font-normal text-secondary mb-2">Localização do equipamento:</h4>
          <TextInput
            value={dataChamado.localizacao}
            onChange={({ target }) => handleDataChamado(target.value, 'localizacao')}
            placeholder=""
          />
        </div>
        <div className="w-full md:w-1/4">
          <h4 className="font-normal text-secondary mb-2">Maquina parada:</h4>
          <select
            value={dataChamado.maquinaParada}
            onChange={({ target }) => handleDataChamado(target.value, 'maquinaParada')}
            className="
              h-[50px]
              w-full
              bg-textSecondary
              text-text
              p-2
              border-textSecondary
              outline-none
              focus:border-text
              bg-textSecondary
              rounded-md
            "
          >
            <option value={false}>Não</option>
            <option value>Sim</option>
          </select>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center mt-4">
        <h4 className="font-normal text-secondary mb-2">Descrição do chamado:</h4>
        <TextInput
          textarea
          value={dataChamado.descricao}
          onChange={({ target }) => handleDataChamado(target.value, 'descricao')}
          placeholder=""
        />
      </div>
      <div className="w-full flex flex-col items-end justify-center mt-4">
        <button
          type="button"
          className="flex flex-row items-center bg-secondary rounded ml-2 py-2 px-4"
          onClick={() => createNewChamado()}
        >
          <h3 className="font-semibold text-textSecondary">
            CRIAR CHAMADO
          </h3>
        </button>
      </div>
    </div>
  );
}
