/* eslint-disable default-param-last */
/* eslint-disable no-unused-vars */
import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userLoginRequest: ['data'],
  userLoginSuccess: null,
  userDataRequest: null,
  userDataSuccess: ['data'],
  createNewUsersRequest: ['data'],
  createNewUsersSuccess: null,
  setNewUserSuccess: ['data'],
  loadingCancel: null,
});

export const UserTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  userData: null,
  newUserSuccess: false,
  loading: false,
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_LOGIN_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.USER_LOGIN_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.USER_DATA_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.USER_DATA_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    userData: data,
    loading: false,
  }),
  [Types.CREATE_NEW_USERS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.CREATE_NEW_USERS_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    newUserSuccess: true,
    loading: false,
  }),
  [Types.SET_NEW_USER_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    newUserSuccess: data,
    loading: false,
  }),
  [Types.LOADING_CANCEL]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
});
