import React from 'react';
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { BrowserRouter as Router } from 'react-router-dom';
import { Notifications } from 'react-push-notification';
import 'react-notifications/lib/notifications.css';

import './config/reactotron';
import Routes from './routes';
import store from './store';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes />
        <NotificationContainer />
        <Notifications />
      </Router>
    </Provider>
  );
}

export default App;
