/* eslint-disable default-param-last */
/* eslint-disable no-unused-vars */
import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  sendPushRequest: ['data'],
  sendPushSuccess: null,
  searchClientRequest: ['name', 'page', 'query'],
  searchClientSuccess: ['clients'],
  searchClientInfoRequest: ['payload'],
  searchClientInfoSuccess: ['info', 'contatos'],
  getDestaquesRequest: null,
  getDestaquesSuccess: ['data'],
  updateDestaquesRequest: ['data', 'id'],
  updateDestaquesSuccess: null,
  deleteDestaquesRequest: ['id'],
  deleteDestaquesSuccess: null,
  createDestaquesRequest: ['url', 'data'],
  createDestaquesSuccess: null,
  getChamadosRequest: null,
  getChamadosSuccess: ['data'],
  getChamadosAtribuidoRequest: null,
  getChamadosAtribuidoSuccess: ['data'],
  createChamadosRequest: ['data'],
  createChamadosSuccess: null,
  updateChamadosRequest: ['data', 'id'],
  updateChamadosSuccess: null,
  atribuirChamadoRequest: ['id'],
  atribuirChamadoSuccess: null,
  desatribuirChamadoRequest: ['id'],
  desatribuirChamadoSuccess: null,
  getChamadoStatusRequest: null,
  getChamadoStatusSuccess: ['data'],
  getChamadoVincStatusRequest: ['id'],
  getChamadoVincStatusSuccess: ['data'],
  createChamadoStatusRequest: ['data'],
  createChamadoStatusSuccess: null,
  setChamadoSelect: ['data'],
  setClientSelect: ['client'],
  setClientSearchModalStatus: ['data'],
  loadingCancel: null,
});

export const ClienteTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  novidades: null,
  chamados: null,
  chamadosAtribuidos: null,
  clientsSearch: null,
  chamadoSelect: null,
  chamadoStatus: null,
  chamadoVincStatus: null,
  clientContacts: null,
  clientSelected: null,
  clientSearchModalStatus: false,
  clientInfo: null,
  loading: false,
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SEARCH_CLIENT_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.SEARCH_CLIENT_SUCCESS]: (state = INITIAL_STATE, { clients }) => ({
    ...state,
    loading: false,
    clientsSearch: clients,
  }),

  [Types.SEARCH_CLIENT_INFO_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.SEARCH_CLIENT_INFO_SUCCESS]: (
    state = INITIAL_STATE,
    { info, contatos },
  ) => ({
    ...state,
    loading: false,
    clientContacts: contatos,
    clientInfo: info,
  }),
  [Types.SEND_PUSH_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.SEND_PUSH_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.GET_DESTAQUES_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.GET_DESTAQUES_SUCCESS]: (state = INITIAL_STATE, action) => ({
    ...state,
    novidades: action.data,
    loading: false,
  }),
  [Types.UPDATE_DESTAQUES_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.UPDATE_DESTAQUES_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.GET_CHAMADO_STATUS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.GET_CHAMADO_STATUS_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    chamadoStatus: data,
    loading: false,
  }),
  [Types.GET_CHAMADO_VINC_STATUS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.GET_CHAMADO_VINC_STATUS_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    chamadoVincStatus: data,
    loading: false,
  }),
  [Types.CREATE_CHAMADO_STATUS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.CREATE_CHAMADO_STATUS_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.DELETE_DESTAQUES_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.DELETE_DESTAQUES_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.CREATE_DESTAQUES_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.CREATE_DESTAQUES_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.GET_CHAMADOS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.GET_CHAMADOS_SUCCESS]: (state = INITIAL_STATE, action) => ({
    ...state,
    chamados: action.data,
    loading: false,
  }),
  [Types.GET_CHAMADOS_ATRIBUIDO_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.GET_CHAMADOS_ATRIBUIDO_SUCCESS]: (state = INITIAL_STATE, action) => ({
    ...state,
    chamadosAtribuidos: action.data,
    loading: false,
  }),
  [Types.CREATE_CHAMADOS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.CREATE_CHAMADOS_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.UPDATE_CHAMADOS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.UPDATE_CHAMADOS_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.ATRIBUIR_CHAMADO_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.ATRIBUIR_CHAMADO_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.DESATRIBUIR_CHAMADO_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),
  [Types.DESATRIBUIR_CHAMADO_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.SET_CHAMADO_SELECT]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    chamadoSelect: data,
  }),
  [Types.SET_CLIENT_SELECT]: (state = INITIAL_STATE, { client }) => ({
    ...state,
    loading: false,
    clientSelected: client,
  }),
  [Types.SET_CLIENT_SEARCH_MODAL_STATUS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    loading: false,
    clientSearchModalStatus: data,
  }),
  [Types.LOADING_CANCEL]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
});
