/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  IconButton,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import styles from './styles';
import TableRowComponent from './TableRow';

export default function DataTable({
  column,
  data,
  selectionAction,
  prevAction,
  nextAction,
  disableAutoFocus,
}) {
  const classes = styles();
  const [rowSelectedCount, setRowSelectedCount] = useState(0);
  const [querySearch, setQuerySearch] = useState('');
  const inputRef = React.useRef();

  const handleSelectRow = useCallback(
    (e) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (rowSelectedCount === 0) {
          return setRowSelectedCount(0);
        }
        setRowSelectedCount(rowSelectedCount - 1);
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (data && rowSelectedCount === data.docs.length - 1) {
          return null;
        }
        setRowSelectedCount(rowSelectedCount + 1);
      }

      if (e.key === 'PageUp') {
        e.preventDefault();
        if (data && rowSelectedCount - 10 < 0) {
          return setRowSelectedCount(0);
        }
        setRowSelectedCount(rowSelectedCount - 10);
      }

      if (e.key === 'PageDown') {
        e.preventDefault();
        if (data && rowSelectedCount + 10 > data.docs.length - 1) {
          return setRowSelectedCount(data.docs.length - 1);
        }
        setRowSelectedCount(rowSelectedCount + 10);
      }

      if ((e.keyCode >= 48 && e.keyCode <= 90) || e.keyCode === 8) {
        if (disableAutoFocus) {
          return null;
        }
        inputRef.current.focus();
      }
    },
    [rowSelectedCount],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleSelectRow);

    return () => {
      window.removeEventListener('keydown', handleSelectRow);
    };
  }, [rowSelectedCount]);

  function RenderPagination() {
    return (
      <div className={classes.paginationRow}>
        <div className={classes.rowAlign}>
          <div className={classes.rowAlign} style={{ marginRight: 10 }}>
            <Typography variant="subtitle2" color="primary">
              Paginas:
              {' '}
            </Typography>
            <Typography variant="subtitle2">
              {data && data.page}
              -
              {data && data.totalPages}
            </Typography>
          </div>
          <div className={classes.rowAlign}>
            <Typography variant="subtitle2" color="primary">
              Total do resultado:
              {' '}
            </Typography>
            <Typography variant="subtitle2">
              {data && data.totalDocs}
            </Typography>
          </div>
        </div>
        <div className={classes.rowAlign}>
          <IconButton
            onClick={() => prevAction()}
            disabled={data && data.hasPrevPage === false}
            aria-label="prev"
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            onClick={() => nextAction()}
            disabled={data && data.hasNextPage === false}
            aria-label="next"
          >
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <input
          placeholder="Pesquisar na tabela"
          value={querySearch}
          onChange={({ target }) => setQuerySearch(target.value)}
          ref={inputRef}
          tabIndex="1"
        />
      </div>
      <TableContainer>
        <Table stickyHeader style={{ width: '100%' }} size="small">
          <TableHead>
            <TableRow>
              {column.map((item, index) => (
                <TableCell key={index}>{item.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data && data.docs && (
            <TableBodyRender
              data={data}
              columns={column}
              query={querySearch}
              rowSelectedCount={rowSelectedCount}
              selectionAction={selectionAction}
            />
          )}
        </Table>
        <RenderPagination />
      </TableContainer>
    </>
  );
}

const filterData = (data, columns, query) => {
  const filter = data.docs.filter((item) => {
    for (const column of columns) {
      const itemType = typeof item[column.field];
      if (itemType === 'string') {
        const exist = item[column.field].includes(query.toUpperCase());

        if (exist) {
          return true;
        }
      }
    }
  });
  return filter;
};

function TableBodyRender({
  data,
  columns,
  query,
  rowSelectedCount,
  selectionAction,
}) {
  const dataFiltred = React.useMemo(() => filterData(data, columns, query), [
    query,
    data,
  ]);
  return (
    <TableBody>
      {dataFiltred
        && dataFiltred.map((item, index) => (
          <TableRowComponent
            data={item}
            key={index}
            indexRow={index}
            columns={columns}
            rowSelected={rowSelectedCount}
            rowAction={selectionAction}
          />
        ))}
    </TableBody>
  );
}
