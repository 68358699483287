export const TOKEN_KEY = '@TECNOESTE-APP-GERENCIADOR-Token';
export const TOKEN_FENIX_KEY = '@TECNOESTE-APP-GERENCIADOR-Token-Fenix';
export const USERDATA_KEY = '@TECNOESTE-APP-GERENCIADOR-Userdata';
export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const getTokenFenix = () => sessionStorage.getItem(TOKEN_FENIX_KEY);

export const login = (token) => {
  sessionStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(USERDATA_KEY);
};
