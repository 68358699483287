import { combineReducers } from 'redux';

import { reducer as navigation } from './navigation';
import { reducer as user } from './user';
import { reducer as cliente } from './cliente';
import { reducer as chat } from './chat';
import { reducer as maquinas } from './maquinas';
import { reducer as utils } from './utils';

const reducers = combineReducers({
  user,
  navigation,
  cliente,
  chat,
  maquinas,
  utils,
});

export default reducers;
