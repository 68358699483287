import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UtilsActions from '../../../store/ducks/utils';
// import { Container } from './styles';

export default function OrigemSelect() {
  const dispatch = useDispatch();
  const origemContatoData = useSelector((store) => store.utils.origemContato);
  const origemContatoSelect = useSelector((store) => store.utils.origemContatoSelect);
  return (
    <div className="w-full md:w-1/4 p-2">
      <h4 className="font-normal text-secondary mb-2">Escolha a origem:</h4>
      <select
        value={origemContatoSelect}
        onChange={({ target }) => dispatch(UtilsActions.setOrigemContatoSelect(target.value))}
        className="
          h-[50px]
          w-full
          bg-textSecondary
          text-text
          p-2
          border-textSecondary
          outline-none
          focus:border-text
          bg-textSecondary
          rounded-md
        "
      >
        <option value="-">-</option>
        {origemContatoData && origemContatoData.map((origem) => (
          <option value={origem.nOrigem} key={origem.nOrigem}>
            {origem.cOrigem}
          </option>
        ))}
      </select>
    </div>
  );
}
