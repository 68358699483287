/* eslint-disable no-unused-vars */
import React from 'react';
import ScrollContainer from '../ScrollComponent';
// import { Container } from './styles';

export default function ModalCustom({
  children,
  openState,
  closeAction,
}) {
  return openState && (
    <div className="w-full h-screen absolute bottom-0 left-0 z-[500] bg-gray-800/75 flex items-center justify-center">
      <div className="w-[95%] h-[95%] md:w-[90%] md:h-[90%] bg-white">
        <ScrollContainer>
          {children}
        </ScrollContainer>
      </div>
    </div>
  );
}
