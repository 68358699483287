/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaquinasAction from '../../../store/ducks/maquinas';

export default function DataClientSelect() {
  const dispatch = useDispatch();
  const clientSelect = useSelector((store) => store.cliente.clientSelected);

  function handleMaquinasClienteSelecionado() {
    dispatch(
      MaquinasAction.getMaquinasClientRequest(clientSelect.CODIGOCLIE, 1),
    );
    setTimeout(() => {
      dispatch(
        MaquinasAction.setMaqModalStatus(true),
      );
    }, 200);
  }

  function handleSelectRow(e) {
    if (e.key === 'F9') {
      if (clientSelect) {
        return handleMaquinasClienteSelecionado();
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', (e) => handleSelectRow(e));

    return () => {
      window.removeEventListener('keydown', (e) => handleSelectRow(e));
    };
  }, [clientSelect]);

  return (
    <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full px-2 md:px-4">
      <div className="w-full md:w-1/4 p-2 flex flex-col items-start justify-center">
        <h3 className="text-secondary font-semibold">COD.CLIENTE:</h3>
        <h3>{clientSelect.CODIGOCLIE}</h3>
      </div>
      <div className="w-full md:w-1/4 p-2 flex flex-col items-start justify-center">
        <h3 className="text-secondary font-semibold">CPF/CNPJ:</h3>
        <h3>{clientSelect.CGCCPF}</h3>
      </div>
      <div className="w-full md:w-1/4 p-2 flex flex-col items-start justify-center">
        <h3 className="text-secondary font-semibold">LOCALIZAÇÃO:</h3>
        <h3>{clientSelect.ENDCIDADE}-{clientSelect.ENDUF}</h3>
      </div>
      <div className="w-full md:w-1/4 md:p-2 flex flex-col items-start justify-center">
        <button
          type="button"
          className="flex flex-row items-center bg-secondary rounded ml-2 py-2 px-4"
          onClick={() => handleMaquinasClienteSelecionado()}
        >
          <h3 className="font-semibold text-textSecondary">
            SELECIONAR MAQUINA DO CLIENTE
          </h3>
        </button>
      </div>
    </div>
  );
}
