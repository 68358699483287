/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getToken } from './auth';
import serverUrl from '../utils/serverUrl';

const api = axios.create({
  // baseURL: 'https://api.tecnoeste.net/p21124',
  baseURL: serverUrl,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
