/* eslint-disable no-unused-vars */
import { put, call } from 'redux-saga/effects';
import _ from 'lodash';
import UtilsActions from '../ducks/utils';
import api from '../../services/apiFenix';

export function* getLojas() {
  try {
    const { data } = yield call(api.get, '/rest/selecionaLojas/?complete=1');
    const filter = data.docs.filter((item) => item.GEFATIVADA);
    yield put(UtilsActions.getLojasSuccess(filter));
  } catch (error) {
    console.log(error);
  }
}

export function* getOrigemContato() {
  try {
    const { data } = yield call(api.get, '/rest/codOrigens');
    yield put(UtilsActions.getOrigemContatoSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

// export function* getHistoricoContato() {
//   try {
//     const { data } = yield call(api.get, '/rest/historicoVisitasPec');
//     return yield put(UtilsActions.getHistoricoContatoSuccess(data));
//   } catch (error) {
//     console.log(error);
//   }
// }
