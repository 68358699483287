/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FaFolderOpen, FaSync, FaTools } from 'react-icons/fa';
import { IoIosDocument } from 'react-icons/io';
import { IoCarSharp } from 'react-icons/io5';
import ModalCustom from '../../../components/ModalCustom';

import 'react-vertical-timeline-component/style.min.css';

// import { Container } from './styles';

export default function ResumeTimeline({ open, setOpen }) {
  const chamadoSelect = useSelector((store) => store.cliente.chamadoSelect);
  const [timelineData, setTimelineData] = useState(null);

  useEffect(() => {
    if (chamadoSelect) {
      if (chamadoSelect.timeline && chamadoSelect.timeline.length > 0) {
        const formatTimeline = [];
        const spreedTimeline = [...chamadoSelect.timeline];
        spreedTimeline.map((timeline, key) => {
          if (key === 0) {
            const descData = timeline.createdBy === 'system-app'
              ? 'Chamado criado por nossos consultores.'
              : 'Chamado criado pelo cliente e entrou na fila para sincronização';
            const setData = {
              title: 'Chamado aberto',
              description: descData,
              registeredAt: format(typeof timeline.registeredAt === 'string'
                ? parseISO(timeline.registeredAt)
                : timeline.registeredAt, 'dd/MM/yyyy - hh:MM:ss'),
              icon: <FaFolderOpen />,
              color: 'rgb(33, 150, 243)',
            };

            return formatTimeline.push(setData);
          }
          if (key === 1) {
            if (timeline.createdBy === 'system-app') {
              const descData = 'Chamado sincronizado com nossos servidores.';
              const setData = {
                title: 'Chamado sincronizado',
                description: descData,
                registeredAt: format(typeof timeline.registeredAt === 'string'
                  ? parseISO(timeline.registeredAt)
                  : timeline.registeredAt, 'dd/MM/yyyy - hh:MM:ss'),
                icon: <FaSync />,
                color: '#34B7AF',
              };

              return formatTimeline.push(setData);
            }
          }

          if (timeline.messageFile) {
            const formatFileData = (fileData) => {
              const formatType = fileData.mimetype.split('/');
              const extensionName = formatType[1];

              if (formatType[0] === 'image') {
                return `imagem (${extensionName})`;
              }

              if (formatType[0] === 'video') {
                return `video (${extensionName})`;
              }
              if (formatType[0] === 'application') {
                return `documento (${extensionName})`;
              }

              return `anexo (${extensionName})`;
            };
            const setData = {
              title: timeline.createdRef === 'Employee' ? 'Consultor enviou um arquivo' : 'Cliente enviou um arquivo',
              description: `Foi enviado um arquivo do tipo ${formatFileData(timeline.fileData)}`,
              registeredAt: format(typeof timeline.registeredAt === 'string'
                ? parseISO(timeline.registeredAt)
                : timeline.registeredAt, 'dd/MM/yyyy - hh:MM:ss'),
              icon: <IoIosDocument />,
              color: '#D166DD',
            };

            return formatTimeline.push(setData);
          }

          if (timeline.createdBy === 'system-app' && timeline.title.includes('OS criada.')) {
            const descData = 'Criado OS e atribuiada ao chamado';
            const setData = {
              title: 'Criação e atribuição da OS',
              description: descData,
              registeredAt: format(typeof timeline.registeredAt === 'string'
                ? parseISO(timeline.registeredAt)
                : timeline.registeredAt, 'dd/MM/yyyy - hh:MM:ss'),
              icon: <FaTools />,
              color: '#D99F3A',
            };

            return formatTimeline.push(setData);
          }
          if (timeline.createdBy === 'system-app' && timeline.title.includes('iniciou o deslocamento.')) {
            const descData = 'O tecnico iniciou o deslocamento até o cliente pelo Trecho App';
            const setData = {
              title: 'O técnico iniciou o deslocamento',
              description: descData,
              registeredAt: format(typeof timeline.registeredAt === 'string'
                ? parseISO(timeline.registeredAt)
                : timeline.registeredAt, 'dd/MM/yyyy - hh:MM:ss'),
              icon: <IoCarSharp />,
              color: '#E55137',
            };

            return formatTimeline.push(setData);
          }

          return null;
        });

        setTimelineData(formatTimeline);
      }
    }
  }, [chamadoSelect]);

  return (
    <ModalCustom
      openState={open}
      closeAction={() => setOpen(!open)}
    >
      <div className="w-full h-full flex flex-col uppercase">
        <div className="flex flex-row bg-secondary p-1 md:p-4 items-center justify-between w-11/12 md:w-full pb-2 border-b">
          <h3 className="font-bold text-lg text-white">TIMELINE DO CHAMADO</h3>

          <button type="button" onClick={() => setOpen(!open)}>
            <h3 className="font-semibold text-white">Fechar</h3>
          </button>
        </div>
        <div className="p-1 md:p-4">
          {timelineData && (
          <VerticalTimeline
            layout="1-column-left"
            lineColor="#003d70"
          >
            {timelineData.map((time) => (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: time.color, color: '#fff' }}
                contentArrowStyle={{ borderRight: `7px solid ${time.color}` }}
                date={time.registeredAt}
                iconStyle={{ background: time.color, color: '#fff' }}
                icon={time.icon}
              >
                <h3 className="vertical-timeline-element-title">{time.title}</h3>
                <p>
                  {time.description}
                </p>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
          )}
        </div>
      </div>
    </ModalCustom>
  );
}
