import { all, takeLatest } from 'redux-saga/effects';

import { UserTypes } from '../ducks/user';
import { userLogin, userData, createUser } from './user';

import { ClienteTypes } from '../ducks/cliente';
import {
  sendPush,
  getDestaques,
  updateDestaques,
  deleteDestaques,
  createDestaques,
  getChamados,
  updateChamados,
  searchClient,
  searchClientInfo,
  createChamados,
  getChamadoStatus,
  createChamadoStatus,
  getChamadoVincStatus,
  getChamadosAtribuido,
  atribuirChamado,
  desatribuirChamado,
} from './cliente';

import { ChatTypes } from '../ducks/chat';
import { sendMsg, openChat } from './chat';

import { MaquinasTypes } from '../ducks/maquinas';
import {
  getSlr, getModelo, getMaquinas, getMaquinasClient, setMaquinaSelect,
} from './maquinas';

import { UtilsTypes } from '../ducks/utils';
import { getOrigemContato, getLojas } from './utils';

export default function* rootSaga() {
  yield all([
    takeLatest(UserTypes.USER_LOGIN_REQUEST, userLogin),
    takeLatest(UserTypes.USER_DATA_REQUEST, userData),
    takeLatest(UserTypes.CREATE_NEW_USERS_REQUEST, createUser),
    takeLatest(ClienteTypes.SEND_PUSH_REQUEST, sendPush),
    takeLatest(ClienteTypes.GET_DESTAQUES_REQUEST, getDestaques),
    takeLatest(ClienteTypes.UPDATE_DESTAQUES_REQUEST, updateDestaques),
    takeLatest(ClienteTypes.DELETE_DESTAQUES_REQUEST, deleteDestaques),
    takeLatest(ClienteTypes.CREATE_DESTAQUES_REQUEST, createDestaques),
    takeLatest(ClienteTypes.GET_CHAMADOS_REQUEST, getChamados),
    takeLatest(ClienteTypes.GET_CHAMADOS_ATRIBUIDO_REQUEST, getChamadosAtribuido),
    takeLatest(ClienteTypes.CREATE_CHAMADOS_REQUEST, createChamados),
    takeLatest(ClienteTypes.UPDATE_CHAMADOS_REQUEST, updateChamados),
    takeLatest(ClienteTypes.SEARCH_CLIENT_REQUEST, searchClient),
    takeLatest(ClienteTypes.SEARCH_CLIENT_INFO_REQUEST, searchClientInfo),
    takeLatest(ClienteTypes.GET_CHAMADO_STATUS_REQUEST, getChamadoStatus),
    takeLatest(ClienteTypes.CREATE_CHAMADO_STATUS_REQUEST, createChamadoStatus),
    takeLatest(ClienteTypes.GET_CHAMADO_VINC_STATUS_REQUEST, getChamadoVincStatus),
    takeLatest(ClienteTypes.ATRIBUIR_CHAMADO_REQUEST, atribuirChamado),
    takeLatest(ClienteTypes.DESATRIBUIR_CHAMADO_REQUEST, desatribuirChamado),
    takeLatest(ChatTypes.SEND_MESSAGE_REQUEST, sendMsg),
    takeLatest(ChatTypes.OPEN_CHAT_REQUEST, openChat),
    takeLatest(MaquinasTypes.GET_SLR_REQUEST, getSlr),
    takeLatest(MaquinasTypes.GET_MODELO_REQUEST, getModelo),
    takeLatest(MaquinasTypes.GET_MAQUINA_REQUEST, getMaquinas),
    takeLatest(MaquinasTypes.GET_MAQUINAS_CLIENT_REQUEST, getMaquinasClient),
    takeLatest(MaquinasTypes.SET_SELECT_MAQUINA_REQUEST, setMaquinaSelect),
    takeLatest(UtilsTypes.GET_ORIGEM_CONTATO_REQUEST, getOrigemContato),
    takeLatest(UtilsTypes.GET_LOJAS_REQUEST, getLojas),
  ]);
}
