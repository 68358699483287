/* eslint-disable default-param-last */
import { createReducer, createActions } from 'reduxsauce';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getMaquinasClientRequest: ['codclient', 'page'],
  getMaquinasClientSuccess: ['maquinas'],
  getSlrRequest: null,
  getSlrSuccess: ['slrs'],
  getModeloRequest: ['slr', 'modelo', 'page'],
  getModeloSuccess: ['modelos'],
  getMaquinaRequest: ['slr', 'modelo', 'serie', 'page'],
  getMaquinaSuccess: ['maquinas'],
  setSelectSlr: ['slr'],
  setSelectSerie: ['serie'],
  setSelectModelo: ['modelo'],
  setSelectMaquinaRequest: ['maquina'],
  setSelectMaquinaSuccess: ['maquina'],
  setMaqModalStatus: ['status'],
  loadingCancel: null,
});

export const MaquinasTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = {
  maquinasResult: null,
  maquinaSelecionada: null,
  slrResult: null,
  slrSelecionada: '',
  serieSelecionada: '',
  modelosResult: null,
  modeloSelecionado: '',
  maquinaModalStatus: false,
  loading: false,
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MAQUINAS_CLIENT_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),

  [Types.GET_MAQUINAS_CLIENT_SUCCESS]: (
    state = INITIAL_STATE,
    { maquinas },
  ) => ({ ...state, loading: false, maquinasResult: maquinas }),

  [Types.GET_SLR_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),

  [Types.GET_SLR_SUCCESS]: (state = INITIAL_STATE, { slrs }) => ({
    ...state,
    loading: false,
    slrResult: slrs,
  }),

  [Types.GET_MODELO_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),

  [Types.GET_MODELO_SUCCESS]: (state = INITIAL_STATE, { modelos }) => ({
    ...state,
    loading: false,
    modelosResult: modelos,
  }),

  [Types.GET_MAQUINA_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),

  [Types.GET_MAQUINA_SUCCESS]: (state = INITIAL_STATE, { maquinas }) => ({
    ...state,
    loading: false,
    maquinasResult: maquinas,
  }),

  [Types.SET_SELECT_MAQUINA_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
  [Types.SET_SELECT_MAQUINA_SUCCESS]: (state = INITIAL_STATE, { maquina }) => ({
    ...state,
    loading: false,
    maquinaSelecionada: maquina,
  }),

  [Types.SET_SELECT_SLR]: (state = INITIAL_STATE, { slr }) => ({
    ...state,
    loading: false,
    slrSelecionada: slr,
  }),

  [Types.SET_SELECT_SERIE]: (state = INITIAL_STATE, { serie }) => ({
    ...state,
    loading: false,
    serieSelecionada: serie,
  }),

  [Types.SET_SELECT_MODELO]: (state = INITIAL_STATE, { modelo }) => ({
    ...state,
    loading: false,
    modeloSelecionado: modelo,
  }),

  [Types.SET_MAQ_MODAL_STATUS]: (state = INITIAL_STATE, { status }) => ({
    ...state,
    loading: false,
    maquinaModalStatus: status,
  }),

  [Types.LOADING_CANCEL]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),
});
